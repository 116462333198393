import { type WordType } from '../../../types';

const advertisement: WordType = {
    word: `advertisement`,
    pronUK: `/ədˈvɜː.tɪs.mənt/`,
    pronUS: `/ˌæd.vɚˈtaɪz.mənt/`,
    types: [
        {
            typeName: `noun`,
            mean: `Quảng cáo, mục giao vặt, giấy thông cáo`,
            ex: [
                {
                    sentence: `I saw an advertisement for the new restaurant that opened in town.`,
                    mean: `Tôi thấy một quảng cáo về một nhà hàng mới mở trong thị trấn.`
                },
                {
                    sentence: `Many companies run advertisements in the magazine.`,
                    mean: `Nhiều công ty chạy quảng cáo trên tạp chí.`
                }
            ]
        }
    ]
};

export default advertisement;
