import Container from 'qnb-ui/src/components/Container';
import Navigation from '@components/Navigation';
import { pages } from '@routers/linkPages';
import SidebarRight from '@views/frontend/components/SidebarRight';
import { MainPost, NormalPost } from '@views/frontend/components/post';
import Footer from '../components/Footer';
import styles from './Posts.module.scss';

function Posts() {
    return (
        <div className={`${styles.root} page`}>
            <Navigation pages={pages} />

            <Container className={styles.wapper}>
                <div className={styles.mainLeft}>
                    <div className={styles.listPost}>
                        <MainPost />
                        <NormalPost />
                        <NormalPost />
                        <NormalPost />
                        <NormalPost />
                        <NormalPost />
                        <NormalPost />
                    </div>
                </div>
                <div className={styles.sidebarRight}>
                    <SidebarRight />
                </div>
            </Container>
            <Footer />
        </div>
    );
}

export default Posts;
