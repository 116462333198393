import _ from 'lodash';
import Box from 'qnb-ui/src/components/Box';
import Heading from 'qnb-ui/src/components/Heading';
import Text from 'qnb-ui/src/components/Text';

import styles from './Template.module.scss';
import * as audio from './data/audio';

export type TemplateProps = {
    title: string;
    text: string[];
    audioKey: string;
};

function Template(props: TemplateProps) {
    const { title, text, audioKey } = props;

    return (
        <Box className={styles.container} direction="horizontal">
            <div>
                <Heading
                    as="h3"
                    style={{
                        textAlign: 'center'
                    }}
                >
                    {title}
                </Heading>
                <br />
                {_.map(text, t => (
                    <>
                        <Text className={styles.text} size="medium">
                            {t}
                        </Text>
                        <br />
                    </>
                ))}
            </div>
            <div className={styles.audioLayout}>
                <audio controls src={audio[audioKey]} loop></audio>
            </div>
        </Box>
    );
}

export default Template;
