import _ from 'lodash';

export type SentenceType = {
    name: string;
    data: string;
};

export const getData = () => {
    const aa = [
        `Can you pass me the salt, please? <=> /kæn juː pæs miː ðə sɔlt pliːz/ <=> Bạn có thể đưa muối cho tôi được không?`,
        `She enjoys reading books in her free time. <=> /ʃiː ɪnˈʤɔɪz ˈriːdɪŋ bʊks ɪn hɜr friː taɪm/ <=> Cô ấy thích đọc sách vào thời gian rảnh rỗi của mình.`,
        `What time does the movie start? <=> /wʌt taɪm dʌz ðə ˈmuːvi stɑrt/ <=> Bộ phim bắt đầu vào lúc mấy giờ?`,
        `He needs to buy some groceries for dinner. <=> /hiː niːdz tuː baɪ sʌm ˈɡroʊsəriz fɔr ˈdɪnər/ <=> Anh ấy cần mua vài loại thực phẩm cho bữa tối.`,
        `I have to finish this report by tomorrow. <=> /aɪ hæv tuː ˈfɪnɪʃ ðɪs rɪˈpɔrt baɪ təˈmɑr.oʊ/ <=> Tôi phải hoàn thành báo cáo này vào ngày mai.`,
        `She usually takes a walk after dinner. <=> /ʃiː ˈjuːʒəli teɪks ə wɔk ˈæftər ˈdɪnər/ <=> Cô ấy thường đi dạo sau bữa tối.`,
        `Could you please repeat that? <=> /kʊd juː pliːz rɪˈpiːt ðæt/ <=> Bạn có thể lặp lại được không?`,
        `They enjoy going for picnics in the countryside. <=> /ðeɪ ɪnˈʤɔɪ ˈɡoʊɪŋ fɔr ˈpɪknɪks ɪn ðə ˈkʌntrɪˌsaɪd/ <=> Họ thích đi dã ngoại ở nông thôn.`,
        `He always brings his lunch to work. <=> /hiː ˈɔlˌweɪz brɪŋz hɪz lʌntʃ tuː wɜrk/ <=> Anh ấy luôn mang cơm đi làm.`,
        `We need to book tickets for the concert. <=> /wiː niːd tuː bʊk ˈtɪkɪts fɔr ðə ˈkɑnsərt/ <=> Chúng tôi cần đặt vé cho buổi hòa nhạc.`,
        `She usually wakes up early in the morning. <=> /ʃiː ˈjuːʒəli weɪks ʌp ˈɜrli ɪn ðə ˈmɔrnɪŋ/ <=> Cô ấy thường dậy sớm vào buổi sáng.`,
        `What's your favorite type of music? <=> /wʌts jɔːr ˈfeɪvərɪt taɪp əv ˈmjuːzɪk/ <=> Thể loại nhạc yêu thích của bạn là gì?`,
        `They often go hiking on the weekends. <=> /ðeɪ ˈɔfən ɡoʊ ˈhaɪkɪŋ ɒn ðə ˈwiːkˌɛndz/ <=> Họ thường đi leo núi vào cuối tuần.`,
        `Could you lend me a pen, please? <=> /kʊd juː lɛnd miː ə pɛn pliːz/ <=> Bạn có thể cho tôi mượn một cái bút được không?`,
        `She doesn't like spicy food. <=> /ʃiː ˈdəzənt ˈlaɪk ˈspaɪsi fuːd/ <=> Cô ấy không thích ăn đồ cay.`,
        `We often have barbecues with friends in the summer. <=> /wiː ˈɔfən hæv ˈbɑrbiˌkjuːz wɪð frɛndz ɪn ðə ˈsʌmər/ <=> Chúng tôi thường tổ chức buổi nướng với bạn bè vào mùa hè.`,
        `He usually drinks coffee in the morning. <=> /hiː ˈjuːʒəli drɪŋks ˈkɒfi ɪn ðə ˈmɔrnɪŋ/ <=> Anh ấy thường uống cà phê vào buổi sáng.`,
        `Could you help me with this math problem? <=> /kʊd juː hɛlp miː wɪð ðɪs mæθ ˈprɑbləm/ <=> Bạn có thể chỉ giúp tôi bài toán này được không?`,
        `She often goes swimming at the beach. <=> /ʃiː ˈɔfən ɡoʊz ˈswɪmɪŋ æt ðə biːtʃ/ <=> Cô ấy thường đi bơi ở bãi biển.`,
        `We need to buy some new clothes for the party. <=> /wiː niːd tuː baɪ sʌm ˈnju kləʊðz fɔr ðə ˈpɑrti/ <=> Chúng tôi cần mua vài bộ quần áo mới cho bữa tiệc.`,
        `What's your favorite movie genre? <=> /wʌts jɔːr ˈfeɪvərɪt ˈmuːvi ˈʒɑnrə/ <=> Thể loại phim yêu thích của bạn là gì?`,
        `They often go for a walk in the park after dinner. <=> /ðeɪ ˈɔfən ɡoʊ fɔr ə wɔk ɪn ðə pɑrk ˈæftər ˈdɪnər/ <=> Họ thường đi dạo trong công viên sau bữa tối.`,
        `Can I have a glass of water, please? <=> /kæn aɪ hæv ə ɡlæs əv ˈwɔtər pliːz/ <=> Làm ơn cho tôi xin một cốc nước được không?`,
        `She usually goes jogging in the morning. <=> /ʃiː ˈjuːʒəli ɡoʊz ˈʤɑɡɪŋ ɪn ðə ˈmɔrnɪŋ/ <=> Cô ấy thường đi chạy bộ vào buổi sáng.`,
        `We often visit our grandparents on weekends. <=> /wiː ˈɔfən ˈvɪzɪt ˈaʊər ˈɡrænˌpɛrənts ɒn ˈwiːkˌɛndz/ <=> Chúng tôi thường ghé thăm ông bà vào cuối tuần.`,
        `Could you open the window, please? <=> /kʊd juː ˈoʊpən ðə ˈwɪndoʊ pliːz/ <=> Bạn có thể mở cửa sổ được không?`,
        `She usually listens to music while working. <=> /ʃiː ˈjuːʒəli ˈlɪsənz tuː ˈmjuːzɪk ˈwaɪl ˈwɜrkɪŋ/ <=> Cô ấy thường nghe nhạc khi làm việc.`,
        `They often go camping in the mountains. <=> /ðeɪ ˈɔfən ɡoʊ ˈkæmpɪŋ ɪn ðə ˈmaʊntənz/ <=> Họ thường đi cắm trại ở núi.`,
        `Can you speak more slowly, please? <=> /kæn juː spiːk mɔr ˈsloʊli pliːz/ <=> Bạn có thể nói chậm hơn được không?`,
        `She usually reads a book before going to bed. <=> /ʃiː ˈjuːʒəli riːdz ə bʊk bɪˈfɔr ˈɡoʊɪŋ tuː bɛd/ <=> Cô ấy thường đọc sách trước khi đi ngủ.`,
        `What's your favorite color? <=> /wʌts jɔːr ˈfeɪvərɪt ˈkʌlər/ <=> Màu sắc yêu thích của bạn là gì?`,
        `We often go shopping on Saturdays. <=> /wiː ˈɔfən ɡoʊ ˈʃɑpɪŋ ɒn ˈsætərˌdeɪz/ <=> Chúng tôi thường đi mua sắm vào các ngày thứ bảy.`,
        `Could you please turn off the lights? <=> /kʊd juː pliːz tɜrn ɔf ðə laɪts/ <=> Bạn có thể tắt đèn được không?`,
        `She usually practices yoga in the evening. <=> /ʃiː ˈjuːʒəli ˈpræktɪsɪz ˈjoʊɡə ɪn ðə ˈivnɪŋ/ <=> Cô ấy thường tập yoga vào buổi tối.`,
        `We often go to the gym on weekdays. <=> /wiː ˈɔfən ɡoʊ tuː ðə ʤɪm ɒn ˈwiːkˌdeɪz/ <=> Chúng tôi thường đi tập gym vào các ngày trong tuần.`,
        `Can you help me with my homework, please? <=> /kæn juː hɛlp miː wɪð maɪ ˈhoʊmˌwɜrk pliːz/ <=> Bạn có thể giúp tôi làm bài tập về nhà được không?`,
        `She usually writes in her journal before going to sleep. <=> /ʃiː ˈjuːʒəli raɪts ɪn hɜr ˈʤɜrnəl bɪˈfɔr ˈɡoʊɪŋ tuː slip/ <=> Cô ấy thường viết vào nhật ký trước khi đi ngủ.`,
        `We often have family dinners on Sundays. <=> /wiː ˈɔfən hæv ˈfæməli ˈdɪnərz ɒn ˈsʌndeɪz/ <=> Chúng tôi thường có bữa tối gia đình vào Chủ Nhật.`,
        `Could you please pass me the menu? <=> /kʊd juː pliːz pæs miː ðə ˈmɛnjuː/ <=> Bạn có thể đưa thực đơn cho tôi được không?`,
        `She usually meditates in the morning. <=> /ʃiː ˈjuːʒəli ˈmɛdɪˌteɪts ɪn ðə ˈmɔrnɪŋ/ <=> Cô ấy thường thiền vào buổi sáng.`,
        `We often go for a run in the park. <=> /wiː ˈɔfən ɡoʊ fɔr ə rʌn ɪn ðə pɑrk/ <=> Chúng tôi thường đi chạy trong công viên.`,
        `Could you please close the door? <=> /kʊd juː pliːz kloʊz ðə dɔr/ <=> Bạn có thể đóng cửa được không?`,
        `She usually watches movies on weekends. <=> /ʃiː ˈjuːʒəli ˈwɑʧɪz ˈmuːviz ɒn ˈwiːkˌɛndz/ <=> Cô ấy thường xem phim vào cuối tuần.`,
        `We often go to the library to study. <=> /wiː ˈɔfən ɡoʊ tuː ðə ˈlaɪˌbrɛri tuː ˈstʌdi/ <=> Chúng tôi thường đi thư viện để học.`,
        `Could you please turn down the volume? <=> /kʊd juː pliːz tɜrn daʊn ðə ˈvɑljuːm/ <=> Bạn có thể giảm âm lượng được không?`,
        `She usually takes a nap in the afternoon. <=> /ʃiː ˈjuːʒəli teɪks ə næp ɪn ðə ˌæftərˈnun/ <=> Cô ấy thường ngủ trưa vào buổi chiều.`,
        `We often go out for dinner on special occasions. <=> /wiː ˈɔfən ɡoʊ aʊt fɔr ˈdɪnər ɒn ˈspɛʃəl əˈkeɪʒənz/ <=> Chúng tôi thường đi ăn tối vào những dịp đặc biệt.`,
        `Could you please help me with my luggage? <=> /kʊd juː pliːz hɛlp miː wɪð maɪ ˈlʌɡɪdʒ/ <=> Bạn có thể giúp tôi lấy hành lý được không?`,
        `She usually practices meditation in the evening. <=> /ʃiː ˈjuːʒəli ˈpræktɪsɪz ˌmɛdɪˈteɪʃən ɪn ðə ˈivnɪŋ/ <=> Cô ấy thường tập thiền vào buổi tối.`,
        `We often go to the beach during the summer. <=> /wiː ˈɔfən ɡoʊ tuː ðə biːtʃ ˈdʊrɪŋ ðə ˈsʌmər/ <=> Chúng tôi thường đi biển vào mùa hè.`
    ];

    const bb: { name: string; data: string }[] = [];

    _.map(aa, (i, index) => {
        bb.push({
            name: `Sentence${index + 1 < 10 ? '0' : ''}${index + 1}`,
            data: i
        });
    });

    return bb;
};

export default (): SentenceType[] => [
    {
        name: `Sentence01`,
        data: `Can you pass me the salt, please? <=> /kæn juː pæs miː ðə sɔlt pliːz/ <=> Bạn có thể đưa muối cho tôi được không?`
    },
    {
        name: `Sentence02`,
        data: `She enjoys reading books in her free time. <=> /ʃiː ɪnˈʤɔɪz ˈriːdɪŋ bʊks ɪn hɜr friː taɪm/ <=> Cô ấy thích đọc sách vào thời gian rảnh rỗi của mình.`
    },
    {
        name: `Sentence03`,
        data: `What time does the movie start? <=> /wʌt taɪm dʌz ðə ˈmuːvi stɑrt/ <=> Bộ phim bắt đầu vào lúc mấy giờ?`
    },
    {
        name: `Sentence04`,
        data: `He needs to buy some groceries for dinner. <=> /hiː niːdz tuː baɪ sʌm ˈɡroʊsəriz fɔr ˈdɪnər/ <=> Anh ấy cần mua vài loại thực phẩm cho bữa tối.`
    },
    {
        name: `Sentence05`,
        data: `I have to finish this report by tomorrow. <=> /aɪ hæv tuː ˈfɪnɪʃ ðɪs rɪˈpɔrt baɪ təˈmɑr.oʊ/ <=> Tôi phải hoàn thành báo cáo này vào ngày mai.`
    },
    {
        name: `Sentence06`,
        data: `She usually takes a walk after dinner. <=> /ʃiː ˈjuːʒəli teɪks ə wɔk ˈæftər ˈdɪnər/ <=> Cô ấy thường đi dạo sau bữa tối.`
    },
    {
        name: `Sentence07`,
        data: `Could you please repeat that? <=> /kʊd juː pliːz rɪˈpiːt ðæt/ <=> Bạn có thể lặp lại được không?`
    },
    {
        name: `Sentence08`,
        data: `They enjoy going for picnics in the countryside. <=> /ðeɪ ɪnˈʤɔɪ ˈɡoʊɪŋ fɔr ˈpɪknɪks ɪn ðə ˈkʌntrɪˌsaɪd/ <=> Họ thích đi dã ngoại ở nông thôn.`
    },
    {
        name: `Sentence09`,
        data: `He always brings his lunch to work. <=> /hiː ˈɔlˌweɪz brɪŋz hɪz lʌntʃ tuː wɜrk/ <=> Anh ấy luôn mang cơm đi làm.`
    },
    {
        name: `Sentence10`,
        data: `We need to book tickets for the concert. <=> /wiː niːd tuː bʊk ˈtɪkɪts fɔr ðə ˈkɑnsərt/ <=> Chúng tôi cần đặt vé cho buổi hòa nhạc.`
    },
    {
        name: `Sentence11`,
        data: `She usually wakes up early in the morning. <=> /ʃiː ˈjuːʒəli weɪks ʌp ˈɜrli ɪn ðə ˈmɔrnɪŋ/ <=> Cô ấy thường dậy sớm vào buổi sáng.`
    },
    {
        name: `Sentence12`,
        data: `What's your favorite type of music? <=> /wʌts jɔːr ˈfeɪvərɪt taɪp əv ˈmjuːzɪk/ <=> Thể loại nhạc yêu thích của bạn là gì?`
    },
    {
        name: `Sentence13`,
        data: `They often go hiking on the weekends. <=> /ðeɪ ˈɔfən ɡoʊ ˈhaɪkɪŋ ɒn ðə ˈwiːkˌɛndz/ <=> Họ thường đi leo núi vào cuối tuần.`
    },
    {
        name: `Sentence14`,
        data: `Could you lend me a pen, please? <=> /kʊd juː lɛnd miː ə pɛn pliːz/ <=> Bạn có thể cho tôi mượn một cái bút được không?`
    },
    {
        name: `Sentence15`,
        data: `She doesn't like spicy food. <=> /ʃiː ˈdəzənt ˈlaɪk ˈspaɪsi fuːd/ <=> Cô ấy không thích ăn đồ cay.`
    },
    {
        name: `Sentence16`,
        data: `We often have barbecues with friends in the summer. <=> /wiː ˈɔfən hæv ˈbɑrbiˌkjuːz wɪð frɛndz ɪn ðə ˈsʌmər/ <=> Chúng tôi thường tổ chức buổi nướng với bạn bè vào mùa hè.`
    },
    {
        name: `Sentence17`,
        data: `He usually drinks coffee in the morning. <=> /hiː ˈjuːʒəli drɪŋks ˈkɒfi ɪn ðə ˈmɔrnɪŋ/ <=> Anh ấy thường uống cà phê vào buổi sáng.`
    },
    {
        name: `Sentence18`,
        data: `Could you help me with this math problem? <=> /kʊd juː hɛlp miː wɪð ðɪs mæθ ˈprɑbləm/ <=> Bạn có thể chỉ giúp tôi bài toán này được không?`
    },
    {
        name: `Sentence19`,
        data: `She often goes swimming at the beach. <=> /ʃiː ˈɔfən ɡoʊz ˈswɪmɪŋ æt ðə biːtʃ/ <=> Cô ấy thường đi bơi ở bãi biển.`
    },
    {
        name: `Sentence20`,
        data: `We need to buy some new clothes for the party. <=> /wiː niːd tuː baɪ sʌm ˈnju kləʊðz fɔr ðə ˈpɑrti/ <=> Chúng tôi cần mua vài bộ quần áo mới cho bữa tiệc.`
    },
    {
        name: `Sentence21`,
        data: `What's your favorite movie genre? <=> /wʌts jɔːr ˈfeɪvərɪt ˈmuːvi ˈʒɑnrə/ <=> Thể loại phim yêu thích của bạn là gì?`
    },
    {
        name: `Sentence22`,
        data: `They often go for a walk in the park after dinner. <=> /ðeɪ ˈɔfən ɡoʊ fɔr ə wɔk ɪn ðə pɑrk ˈæftər ˈdɪnər/ <=> Họ thường đi dạo trong công viên sau bữa tối.`
    },
    {
        name: `Sentence23`,
        data: `Can I have a glass of water, please? <=> /kæn aɪ hæv ə ɡlæs əv ˈwɔtər pliːz/ <=> Làm ơn cho tôi xin một cốc nước được không?`
    },
    {
        name: `Sentence24`,
        data: `She usually goes jogging in the morning. <=> /ʃiː ˈjuːʒəli ɡoʊz ˈʤɑɡɪŋ ɪn ðə ˈmɔrnɪŋ/ <=> Cô ấy thường đi chạy bộ vào buổi sáng.`
    },
    {
        name: `Sentence25`,
        data: `We often visit our grandparents on weekends. <=> /wiː ˈɔfən ˈvɪzɪt ˈaʊər ˈɡrænˌpɛrənts ɒn ˈwiːkˌɛndz/ <=> Chúng tôi thường ghé thăm ông bà vào cuối tuần.`
    },
    {
        name: `Sentence26`,
        data: `Could you open the window, please? <=> /kʊd juː ˈoʊpən ðə ˈwɪndoʊ pliːz/ <=> Bạn có thể mở cửa sổ được không?`
    },
    {
        name: `Sentence27`,
        data: `She usually listens to music while working. <=> /ʃiː ˈjuːʒəli ˈlɪsənz tuː ˈmjuːzɪk ˈwaɪl ˈwɜrkɪŋ/ <=> Cô ấy thường nghe nhạc khi làm việc.`
    },
    {
        name: `Sentence28`,
        data: `They often go camping in the mountains. <=> /ðeɪ ˈɔfən ɡoʊ ˈkæmpɪŋ ɪn ðə ˈmaʊntənz/ <=> Họ thường đi cắm trại ở núi.`
    },
    {
        name: `Sentence29`,
        data: `Can you speak more slowly, please? <=> /kæn juː spiːk mɔr ˈsloʊli pliːz/ <=> Bạn có thể nói chậm hơn được không?`
    },
    {
        name: `Sentence30`,
        data: `She usually reads a book before going to bed. <=> /ʃiː ˈjuːʒəli riːdz ə bʊk bɪˈfɔr ˈɡoʊɪŋ tuː bɛd/ <=> Cô ấy thường đọc sách trước khi đi ngủ.`
    },
    {
        name: `Sentence31`,
        data: `What's your favorite color? <=> /wʌts jɔːr ˈfeɪvərɪt ˈkʌlər/ <=> Màu sắc yêu thích của bạn là gì?`
    },
    {
        name: `Sentence32`,
        data: `We often go shopping on Saturdays. <=> /wiː ˈɔfən ɡoʊ ˈʃɑpɪŋ ɒn ˈsætərˌdeɪz/ <=> Chúng tôi thường đi mua sắm vào các ngày thứ bảy.`
    },
    {
        name: `Sentence33`,
        data: `Could you please turn off the lights? <=> /kʊd juː pliːz tɜrn ɔf ðə laɪts/ <=> Bạn có thể tắt đèn được không?`
    },
    {
        name: `Sentence34`,
        data: `She usually practices yoga in the evening. <=> /ʃiː ˈjuːʒəli ˈpræktɪsɪz ˈjoʊɡə ɪn ðə ˈivnɪŋ/ <=> Cô ấy thường tập yoga vào buổi tối.`
    },
    {
        name: `Sentence35`,
        data: `We often go to the gym on weekdays. <=> /wiː ˈɔfən ɡoʊ tuː ðə ʤɪm ɒn ˈwiːkˌdeɪz/ <=> Chúng tôi thường đi tập gym vào các ngày trong tuần.`
    },
    {
        name: `Sentence36`,
        data: `Can you help me with my homework, please? <=> /kæn juː hɛlp miː wɪð maɪ ˈhoʊmˌwɜrk pliːz/ <=> Bạn có thể giúp tôi làm bài tập về nhà được không?`
    },
    {
        name: `Sentence37`,
        data: `She usually writes in her journal before going to sleep. <=> /ʃiː ˈjuːʒəli raɪts ɪn hɜr ˈʤɜrnəl bɪˈfɔr ˈɡoʊɪŋ tuː slip/ <=> Cô ấy thường viết vào nhật ký trước khi đi ngủ.`
    },
    {
        name: `Sentence38`,
        data: `We often have family dinners on Sundays. <=> /wiː ˈɔfən hæv ˈfæməli ˈdɪnərz ɒn ˈsʌndeɪz/ <=> Chúng tôi thường có bữa tối gia đình vào Chủ Nhật.`
    },
    {
        name: `Sentence39`,
        data: `Could you please pass me the menu? <=> /kʊd juː pliːz pæs miː ðə ˈmɛnjuː/ <=> Bạn có thể đưa thực đơn cho tôi được không?`
    },
    {
        name: `Sentence40`,
        data: `She usually meditates in the morning. <=> /ʃiː ˈjuːʒəli ˈmɛdɪˌteɪts ɪn ðə ˈmɔrnɪŋ/ <=> Cô ấy thường thiền vào buổi sáng.`
    },
    {
        name: `Sentence41`,
        data: `We often go for a run in the park. <=> /wiː ˈɔfən ɡoʊ fɔr ə rʌn ɪn ðə pɑrk/ <=> Chúng tôi thường đi chạy trong công viên.`
    },
    {
        name: `Sentence42`,
        data: `Could you please close the door? <=> /kʊd juː pliːz kloʊz ðə dɔr/ <=> Bạn có thể đóng cửa được không?`
    },
    {
        name: `Sentence43`,
        data: `She usually watches movies on weekends. <=> /ʃiː ˈjuːʒəli ˈwɑʧɪz ˈmuːviz ɒn ˈwiːkˌɛndz/ <=> Cô ấy thường xem phim vào cuối tuần.`
    },
    {
        name: `Sentence44`,
        data: `We often go to the library to study. <=> /wiː ˈɔfən ɡoʊ tuː ðə ˈlaɪˌbrɛri tuː ˈstʌdi/ <=> Chúng tôi thường đi thư viện để học.`
    },
    {
        name: `Sentence45`,
        data: `Could you please turn down the volume? <=> /kʊd juː pliːz tɜrn daʊn ðə ˈvɑljuːm/ <=> Bạn có thể giảm âm lượng được không?`
    },
    {
        name: `Sentence46`,
        data: `She usually takes a nap in the afternoon. <=> /ʃiː ˈjuːʒəli teɪks ə næp ɪn ðə ˌæftərˈnun/ <=> Cô ấy thường ngủ trưa vào buổi chiều.`
    },
    {
        name: `Sentence47`,
        data: `We often go out for dinner on special occasions. <=> /wiː ˈɔfən ɡoʊ aʊt fɔr ˈdɪnər ɒn ˈspɛʃəl əˈkeɪʒənz/ <=> Chúng tôi thường đi ăn tối vào những dịp đặc biệt.`
    },
    {
        name: `Sentence48`,
        data: `Could you please help me with my luggage? <=> /kʊd juː pliːz hɛlp miː wɪð maɪ ˈlʌɡɪdʒ/ <=> Bạn có thể giúp tôi lấy hành lý được không?`
    },
    {
        name: `Sentence49`,
        data: `She usually practices meditation in the evening. <=> /ʃiː ˈjuːʒəli ˈpræktɪsɪz ˌmɛdɪˈteɪʃən ɪn ðə ˈivnɪŋ/ <=> Cô ấy thường tập thiền vào buổi tối.`
    },
    {
        name: `Sentence50`,
        data: `We often go to the beach during the summer. <=> /wiː ˈɔfən ɡoʊ tuː ðə biːtʃ ˈdʊrɪŋ ðə ˈsʌmər/ <=> Chúng tôi thường đi biển vào mùa hè.`
    }
];
