import Heading from 'qnb-ui/src/components/Heading';
import Text from 'qnb-ui/src/components/Text';

import styles from './ArticleItem.module.scss';
import { ArticleItemProps } from './types';

function ArticleItem(props: ArticleItemProps) {
    const { thumbnail = '', thumbnailAlt, title, author, meta, className = '', isFullWidth, ...rest } = props;

    return (
        <article className={`${styles.root}${isFullWidth ? ' full__Width' : ''} ${className}`} {...rest}>
            <div className={styles.thumbnail}>
                <a className={styles.imageLink} href="/#">
                    <img src={thumbnail} alt={thumbnailAlt} />
                </a>
            </div>
            <div className={styles.content}>
                <div className={styles.contentHeader}>
                    <Heading as={isFullWidth ? 'h3' : 'h4'} className={styles.entryTitle}>
                        <a href="/#">{title}</a>
                    </Heading>
                    {isFullWidth && (
                        <div className={styles.meta}>
                            <Text size="tiny">
                                <span className={styles.metaAuthor}>
                                    by
                                    <a href="/#">{author}</a>
                                </span>
                                <span className={styles.metaDate}>{meta}</span>
                            </Text>
                        </div>
                    )}
                </div>
            </div>
        </article>
    );
}

export default ArticleItem;
