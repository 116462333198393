// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QcpOQHoAjZFt1nO6w2tH{width:100%;min-height:100vh;background-color:#000;color:#fff;font-size:1.1em;display:flex;justify-content:center;align-items:center}`, "",{"version":3,"sources":["webpack://./src/views/frontend/LoveStory/LoveStory.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,gBAAA,CACA,qBAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".root {\n    width: 100%;\n    min-height: 100vh;\n    background-color: #000;\n    color: #fff;\n    font-size: 1.1em;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `QcpOQHoAjZFt1nO6w2tH`
};
export default ___CSS_LOADER_EXPORT___;
