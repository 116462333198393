import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '@components/Logo';
import { HOME_URL } from '../../constants/app';
import NavItem from '../NavItem';
import styles from './Navigation.module.scss';

export type MenuTypeProps = {
    id: string;
    title?: string;
    type?: 'collapse' | 'item' | 'group';
    url?: string;
    icon?: Element | React.ReactElement | JSX.Element | 'list-style';
    breadcrumbs?: boolean;
    children?: MenuTypeProps[];
};

export type NavigationProps = {
    pages: MenuTypeProps[];
};

function Navigation(props: NavigationProps) {
    const { pages } = props;
    const [hasScrolled, setHasScrolled] = useState(false);
    const [showMenu, setShowMenu] = useState(false);

    const handleSlideScroll = e => {
        const position = window.pageYOffset;
        if (position <= 50) setHasScrolled(false);
        else setHasScrolled(true);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleSlideScroll);

        return () => {
            window.removeEventListener('scroll', handleSlideScroll);
        };
    });

    return (
        <div className={`${styles.root}${hasScrolled ? ' has__scrolled' : ''}`}>
            <div className="container" style={{ width: '1170px' }}>
                <nav className={styles.navibar}>
                    <div className={styles.mobiMenu}>
                        <button type="button" onClick={() => setShowMenu(!showMenu)}>
                            <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
                                <path d="M20,17 L20,18 L4,18 L4,17 L20,17 Z M20,12 L20,13 L4,13 L4,12 L20,12 Z M20,7 L20,8 L4,8 L4,7 L20,7 Z" />
                            </svg>
                        </button>
                        <div className={`${styles.mobiMenuContainer}${showMenu ? ' show__menu' : ''}`}>
                            <div className={styles.closeAction}>
                                <button type="button" onClick={() => setShowMenu(false)}>
                                    <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
                                        <path d="M10.9393398,12 L6,7.06066017 C5.70710678,6.76776695 5.70710678,6.29289322 6,6 C6.29289322,5.70710678 6.76776695,5.70710678 7.06066017,6 L12,10.9393398 L16.9393398,6 C17.232233,5.70710678 17.7071068,5.70710678 18,6 C18.2928932,6.29289322 18.2928932,6.76776695 18,7.06066017 L13.0606602,12 L18,16.9393398 C18.2928932,17.232233 18.2928932,17.7071068 18,18 C17.7071068,18.2928932 17.232233,18.2928932 16.9393398,18 L12,13.0606602 L7.06066017,18 C6.76776695,18.2928932 6.29289322,18.2928932 6,18 C5.70710678,17.7071068 5.70710678,17.232233 6,16.9393398 L10.9393398,12 Z" />
                                    </svg>
                                </button>
                            </div>
                            <ul className={styles.mobiListNav}>
                                {pages.map(item => (
                                    <NavItem key={item.id} item={item} level={1} />
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className={styles.logoTop}>
                        <Link to={HOME_URL} target="_self" className={styles.logo}>
                            <Logo />
                        </Link>
                    </div>
                    <div className={styles.navDesktop}>
                        <div className={styles.listNav}>
                            {pages.map(item => (
                                <NavItem key={item.id} item={item} level={1} />
                            ))}
                        </div>
                        <div className={styles.actionTop}>
                            <div className={styles.searchBox}>
                                <button type="button" className={styles.btnSearch}>
                                    <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
                                        <path d="M19.8535534,19.1464466 C20.0488155,19.3417088 20.0488155,19.6582912 19.8535534,19.8535534 C19.6582912,20.0488155 19.3417088,20.0488155 19.1464466,19.8535534 L15.4380219,16.1451287 C14.1187738,17.3000688 12.3911257,18 10.5,18 C6.35786438,18 3,14.6421356 3,10.5 C3,6.35786438 6.35786438,3 10.5,3 C14.6421356,3 18,6.35786438 18,10.5 C18,12.3911257 17.3000688,14.1187738 16.1451287,15.4380219 L19.8535534,19.1464466 Z M17,10.5 C17,6.91014913 14.0898509,4 10.5,4 C6.91014913,4 4,6.91014913 4,10.5 C4,14.0898509 6.91014913,17 10.5,17 C14.0898509,17 17,14.0898509 17,10.5 Z" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Navigation;
