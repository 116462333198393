import _ from 'lodash';
import { useEffect, useRef } from 'react';
import Slider, { type Settings } from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import { windowSpeak } from '../../helpers';
import Card, { CardProps } from '../Card';
import styles from './Slider.module.scss';

export type SliderProps = {
    data: CardProps[];
    settings?: Settings;
    isAutoPlay?: boolean;
    isAutoMp3?: boolean;
};

function SliderWrapper(props: SliderProps) {
    const { data, settings, isAutoPlay, isAutoMp3 } = props;
    const sliderRef = useRef<Slider>(null);

    useEffect(() => {
        !!isAutoMp3 && windowSpeak('en-US', data[0].word);
    }, []);

    useEffect(() => {
        if (sliderRef.current) {
            if (isAutoPlay) {
                sliderRef.current?.slickPause();
            } else {
                sliderRef.current?.slickPlay();
            }
        }
    }, [props.isAutoPlay]);

    const handleAfterChange = (currentSlide: number) => {
        if (!data) return;
        if (isAutoMp3) {
            const currentCard = data[currentSlide];
            const text = currentCard.word;
            windowSpeak('en-US', text);
        }
    };

    return (
        <>
            <Slider
                ref={sliderRef}
                {...settings}
                autoplay={isAutoPlay}
                afterChange={handleAfterChange}
                className={styles.slideContainer}
            >
                {_.map(data, (card: CardProps, index: number) => (
                    <Card key={index} {...card} slug={`word_${card.word}`} />
                ))}
            </Slider>
        </>
    );
}

export default SliderWrapper;
