import Heading from 'qnb-ui/src/components/Heading';
import Text from 'qnb-ui/src/components/Text';
import styles from './AboutMe.module.scss';

function AboutMe() {
    return (
        <aside className={styles.root}>
            <Heading as="h3" className={styles.title}>
                About Me
            </Heading>
            <div className={styles.infomain}>
                <div className={styles.thumbnail}>
                    <img
                        src="https://i.pinimg.com/564x/0a/aa/07/0aaa074e5d590f6597c482563355e120.jpg"
                        alt="About Me"
                    />
                </div>
                <Heading className="me-heading" as="h4">
                    English Teacher
                </Heading>
                <Text className="me-desc">
                    Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque
                    laudantium.
                </Text>
            </div>
        </aside>
    );
}

export default AboutMe;
