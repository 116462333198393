import _ from 'lodash';
import Text from 'qnb-ui/src/components/Text';
import { useEffect, useState } from 'react';

import styles from './EnglishCards.module.scss';
import Settings from './Settings';
import Slider from './components/Slider';
import initData from './data';

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000
};

function EnglishCards() {
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<any[]>([]);
    const [isAutoPlay, setAutoPlay] = useState<boolean>(true);
    const [isAutoMp3, setAutoMp3] = useState<boolean>(true);

    useEffect(() => {
        const initRender = async () => {
            /** get voices  */
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        };

        if (window.document) initRender();
        /** init data */
        if (initData.length) setData(initData);
    }, []);

    return loading ? (
        <Text skin="error">Loading...</Text>
    ) : (
        <div className={styles.app_wrapper}>
            <Settings
                isAuto={isAutoPlay}
                isAutoMp3={isAutoMp3}
                toggleAutoplay={() => setAutoPlay(!isAutoPlay)}
                toggleAutoMp3={() => setAutoMp3(!isAutoMp3)}
            />
            {!!_.size(data) && (
                <Slider data={data} settings={settings} isAutoPlay={isAutoPlay} isAutoMp3={isAutoMp3} />
            )}
        </div>
    );
}

export default EnglishCards;
