import Text from 'qnb-ui/src/components/Text';
import Container from 'qnb-ui/src/components/Container';
import styles from './Footer.module.scss';

function Footer() {
    return (
        <div className={styles.root}>
            <Container>
                <Text size="tiny">© 2023 Markpsy</Text>
            </Container>
        </div>
    );
}

export default Footer;
