import { useEffect, useState } from 'react';
import TypeIt from 'typeit-react';

import styles from './Style.module.scss';
import { initHeart } from './helper';
import iconPlay from './play-icon.png';
import audioUrl from './ptmw.mp3';

function ThreeYear() {
    const [run, setRun] = useState(false);

    const handlePlay = () => {
        setRun(true);
        const audio = new Audio(audioUrl);
        audio.play();
    };
    return (
        <>
            <div className={styles.root}>
                {run && (
                    <TypeIt
                        options={{
                            waitUntilVisible: true,
                            cursorChar: '<span>♡</span>',
                            cursor: {
                                animation: {
                                    options: { duration: 500 }
                                }
                            }
                        }}
                        getBeforeInit={instance => {
                            instance
                                .pause(2000)
                                .type(`My baby I love you so much forever you and I`)
                                .pause(200)
                                .break()
                                .type(`I love you oh~I love you so much forever you and I`)
                                .pause(1500)
                                .break()
                                .type(`My baby I love you so much forever you and I`)
                                .pause(200)
                                .break()
                                .type(`I love you oh I love you so much forever you and I `)
                                .pause(2000);

                            // Remember to return it!
                            return instance;
                        }}
                    />
                )}
                {!run && (
                    <div className={styles.playAction}>
                        <button className={styles.btnPlay} onClick={handlePlay}>
                            <img src={iconPlay} />
                        </button>
                    </div>
                )}
            </div>
            {/* <canvas className={styles.heart} id="heart"></canvas> */}
        </>
    );
}

export default ThreeYear;
