import { type WordType } from '../../../types';

const invitation: WordType = {
    word: `invitation`,
    pronUK: `/ˌɪn.vɪˈteɪ.ʃən/`,
    pronUS: `/ˌɪn.vəˈteɪ.ʃən/`,
    types: [
        {
            typeName: `noun`,
            mean: `Lời mời, thỉnh cầu`,
            ex: [
                {
                    sentence: `Thanks for the invitation to your birthday party.`,
                    mean: `Cảm ơn vì lời mời tới bữa tiệc sinh nhật của bạn.`
                },
                {
                    sentence: `The invitation was written in beautiful italic script.`,
                    mean: `Lời mời được viết bằng chữ in nghiêng rất đẹp.`
                }
            ]
        }
    ]
};

export default invitation;
