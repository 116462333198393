import Heading from 'qnb-ui/src/components/Heading';
import ArticleItem from '../ArticleItem';
import styles from './LatestNews.module.scss';

function LatestNews() {
    return (
        <aside className={styles.root}>
            <Heading as="h3" className={styles.title}>
                Book Reviews
            </Heading>
            <div className={styles.infomain}>
                <ul className={styles.listnew}>
                    <li className={styles.item}>
                        <ArticleItem
                            thumbnail="https://place-hold.it/500x300"
                            title="ISLE OF WINDS NOVEL REVIEW"
                            author="Tony Gray"
                            meta="June 30, 2017"
                            isFullWidth={true}
                        />
                    </li>
                    <li className={styles.item}>
                        <ArticleItem
                            thumbnail="https://place-hold.it/500x300"
                            title="The Fault in our Stars Review"
                            author="Tony Gray"
                            meta="June 30, 2017"
                        />
                    </li>
                    <li className={styles.item}>
                        <ArticleItem
                            thumbnail="https://place-hold.it/500x300"
                            title="Sunday Morning with Good Books"
                            author="Tony Gray"
                            meta="June 30, 2017"
                        />
                    </li>
                    <li className={styles.item}>
                        <ArticleItem
                            thumbnail="https://place-hold.it/500x300"
                            title="The Fault in our Stars Review"
                            author="Tony Gray"
                            meta="June 30, 2017"
                        />
                    </li>
                    <li className={styles.item}>
                        <ArticleItem
                            thumbnail="https://place-hold.it/500x300"
                            title="Sunday Morning with Good Books"
                            author="Tony Gray"
                            meta="June 30, 2017"
                        />
                    </li>
                </ul>
            </div>
        </aside>
    );
}

export default LatestNews;
