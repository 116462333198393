import Tabs, { type TabProps } from 'qnb-ui/src/components/Tabs';
import { useState } from 'react';

import EnglishCards from './EnglishCards';
import Paragraph from './Paragraph';
import ReadingAndListening from './ReadingAndListening';
import SlickSlide from './SlickSlide';
import TypeAndVoice from './TypeAndVoice';

function English() {
    const [activeId, setActiveId] = useState<TabProps['activeId']>('r_l');
    return (
        <>
            <Tabs
                items={[
                    { id: 'vocabulary', label: 'Vocabulary' },
                    { id: 'paragraph', label: 'Paragraph' },
                    { id: 'r_l', label: 'Reading & Listening' },
                    { id: 'type_and_voice', label: 'TypeAndVoice' },
                    { id: 'slick_slide', label: 'Slick Slide' }
                ]}
                activeId={activeId}
                onChange={id => setActiveId(id)}
                headerTitleType="border-bottom"
            />
            <div style={{ padding: '20px' }}>
                {activeId === 'vocabulary' && <EnglishCards />}
                {activeId === 'paragraph' && <Paragraph />}
                {activeId === 'r_l' && <ReadingAndListening />}
                {activeId === 'type_and_voice' && <TypeAndVoice />}
                {activeId === 'slick_slide' && <SlickSlide />}
            </div>
        </>
    );
}

export default English;
