// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HgMonOalA5D6bnumKbtA{display:flex;justify-content:center;gap:15px}.Cv6BnEQ8GjNOVB25Wiph{max-width:475px;margin:20px auto}`, "",{"version":3,"sources":["webpack://./src/views/frontend/English/EnglishCards/components/Slider/Slider.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,sBAAA,CACA,QAAA,CAGJ,sBACI,eAAA,CACA,gBAAA","sourcesContent":["._settings {\n    display: flex;\n    justify-content: center;\n    gap: 15px\n}\n\n.slideContainer {\n    max-width: 475px;\n    margin: 20px auto;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"_settings": `HgMonOalA5D6bnumKbtA`,
	"slideContainer": `Cv6BnEQ8GjNOVB25Wiph`
};
export default ___CSS_LOADER_EXPORT___;
