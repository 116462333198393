import Page_01 from './templates/Page_01';
import Page_02 from './templates/Page_02';

function Home() {
    return (
        <>
            <Page_01 />
            {/* <Page_02 /> */}
        </>
    );
}

export default Home;
