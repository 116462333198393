// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Hz9Lpm4E7G1dO6B3E7wA{position:relative;width:90%;margin:auto;padding:10px;line-height:1.5em;font-family:"Raleway"}.Qt6zYtTmuoIx74DPvfxn{position:absolute;left:0;top:0;width:100%;height:100%}.ehWttt5YksGmf_aW3c8u{position:absolute;left:50%;top:60%;transform:translate(-50%, -50%);z-index:99}`, "",{"version":3,"sources":["webpack://./src/views/frontend/LoveStory/story-list/ThreeYear/ThreeYear.module.scss"],"names":[],"mappings":"AAAA,sBACI,iBAAA,CACA,SAAA,CACA,WAAA,CACA,YAAA,CACA,iBAAA,CACA,qBAAA,CAEJ,sBACI,iBAAA,CACA,MAAA,CACA,KAAA,CACA,UAAA,CACA,WAAA,CAGJ,sBACI,iBAAA,CACA,QAAA,CACA,OAAA,CACA,+BAAA,CACA,UAAA","sourcesContent":[".root {\n    position: relative;\n    width: 90%;\n    margin: auto;\n    padding: 10px; \n    line-height: 1.5em;\n    font-family: \"Raleway\";\n}\n.heart {\n    position: absolute;\n    left:0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n}\n\n.typeLove {\n    position: absolute;\n    left: 50%;\n    top: 60%;\n    transform: translate(-50%, -50%);\n    z-index: 99;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Hz9Lpm4E7G1dO6B3E7wA`,
	"heart": `Qt6zYtTmuoIx74DPvfxn`,
	"typeLove": `ehWttt5YksGmf_aW3c8u`
};
export default ___CSS_LOADER_EXPORT___;
