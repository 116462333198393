import StickyBox from 'react-sticky-box';
import AboutMe from '../AboutMe';
import Social from '../Social';
import LatestNews from '../LatestNews';
import Images from '../Images';
import styles from './SidebarRight.module.scss';

function SidebarRight() {
    return (
        <StickyBox offsetTop={0} offsetBottom={0}>
            <div className={styles.root}>
                <AboutMe />
                <Social />
                <LatestNews />
                <Images />
            </div>
        </StickyBox>
    );
}

export default SidebarRight;
