import { type WordType } from '../../../types';

const preprint: WordType = {
    word: `preprint`,
    pronUK: `/ˈpriː.prɪnt/`,
    pronUS: `/ˈpriː.prɪnt/`,
    types: [
        {
            typeName: `noun`,
            mean: `In sẵn`,
            ex: [
                {
                    sentence: `The following is a preprint of an article for the magazine, due to be published in May.`,
                    mean: `Sau đây là bản in trước của một bài báo đăng trên tạp chí, dự kiến xuất bản vào tháng 5.`
                }
            ]
        }
    ]
};

export default preprint;
