import { type WordType } from '../../../types';

const indicate: WordType = {
    word: `indicate`,
    pronUK: `/ˈɪn.dɪ.keɪt/`,
    pronUS: `/ˈɪn.də.keɪt/`,
    types: [
        {
            typeName: `verb`,
            mean: `Biểu thị, cho hay`,
            ex: [
                {
                    sentence: `Exploratory investigations have indicated large amounts of oil below the sea bed.`,
                    mean: `Các cuộc điều tra thăm dò đã chỉ ra một lượng lớn dầu dưới đáy biển.`
                },
                {
                    sentence: `Please indicate which free gift you would like to receive.`,
                    mean: `Vui lòng cho biết món quà miễn phí mà bạn muốn nhận.`
                }
            ]
        }
    ]
};

export default indicate;
