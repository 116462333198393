import Heading from 'qnb-ui/src/components/Heading';
import ImageItem from './ImageItem';
import styles from './Images.module.scss';

function Images() {
    return (
        <aside className={styles.root}>
            <Heading as="h3" className={styles.title}>
                On Instagram
            </Heading>
            <div className={styles.infomain}>
                <ul className={styles.listinta}>
                    <li className={styles.item}>
                        <ImageItem url="https://i.pinimg.com/564x/36/24/7c/36247c5d673c2c91f2357a7be0337fd9.jpg" />
                    </li>
                    <li className={styles.item}>
                        <ImageItem url="https://i.pinimg.com/564x/e3/4c/07/e34c07341e4290d43e5f1f437a0a7c99.jpg" />
                    </li>
                    <li className={styles.item}>
                        <ImageItem url="https://i.pinimg.com/236x/dc/b9/8f/dcb98f6c0f07f95354020992574ed88f.jpg" />
                    </li>
                    <li className={styles.item}>
                        <ImageItem url="https://i.pinimg.com/474x/57/3a/0e/573a0e40acea8b8f601ce365718bc570.jpg" />
                    </li>
                    <li className={styles.item}>
                        <ImageItem url="https://i.pinimg.com/474x/da/e0/55/dae0552b43507d2ccbf12701272b2030.jpg" />
                    </li>
                    <li className={styles.item}>
                        <ImageItem url="https://i.pinimg.com/564x/37/89/87/378987800327405b438443e104cc5de7.jpg" />
                    </li>
                    <li className={styles.item}>
                        <ImageItem url="https://i.pinimg.com/236x/3b/80/3e/3b803e845a0b185f28b8419c01aa86a6.jpg" />
                    </li>
                    <li className={styles.item}>
                        <ImageItem url="https://i.pinimg.com/474x/e2/8a/a7/e28aa7eb0e6bb568201b8519ed24685b.jpg" />
                    </li>
                    <li className={styles.item}>
                        <ImageItem url="https://i.pinimg.com/236x/46/69/a0/4669a0212820726eeceac7fccff1007c.jpg" />
                    </li>
                </ul>
            </div>
        </aside>
    );
}

export default Images;
