import $ from 'jquery';
import _ from 'lodash';
import Tabs, { type TabProps } from 'qnb-ui/src/components/Tabs';
import { useEffect, useState } from 'react';

import Sentence from './Sentence';
import styles from './TypeAndVoice.module.scss';
import bg from './bg.jpeg';
import * as audio from './data/audio';
import data, { type SentenceType } from './data/data';
import { getData } from './data/data';
import { data_words } from './data/data_words';
import { word_voices } from './data/word_voices';

// import * as wordsAudio from './wordsAudio';

const getwords = () => {
    const words: string[] = [];
    _.each(data_words, i => {
        const sec = i.split(' ');
        _.each(sec, j => {
            if (!_.includes(word_voices, j.toLowerCase()) && !_.includes(words, j)) words.push(j);
        });
    });

    return words;
};

// function getDuration(src) {
//     return new Promise(function (resolve) {
//         var audio = new Audio();
//         $(audio).on('loadedmetadata', function () {
//             audio.play();
//             resolve(audio.duration);
//         });

//         audio.src = src;
//     });
// }
/**
 * 50 câu thông dụng hàng ngày trong tiếng Anh
 */
function TypeAndVoice() {
    const sentenceList = [
        { id: 'background', label: 'Background' },
        ..._.map(data(), (i: SentenceType) => ({
            id: `${i.name}`,
            label: `${i.name}`
        }))
    ];

    const [activeId, setActiveId] = useState<TabProps['activeId']>(sentenceList[0].id);

    const handleNext = () => {
        const index = _.findIndex(sentenceList, i => i.id === activeId);
        sentenceList[index + 1] && setActiveId(sentenceList[index + 1].id);
    };

    return (
        <div className={styles.root}>
            {/* {_.map(getwords(), word => (
                <div>{word}</div>
            ))} */}
            {/* {_.map(word_voices.sort(), word => (
                <div>`{word.toLowerCase()}`,</div>
            ))} */}
            {/* {_.map(getData(), item => (
                <pre>
                    <div>name: `{item.name}`,</div>
                    <div>data: `{item.data}`</div>[[
                </pre>
            ))} */}
            <Tabs
                items={sentenceList}
                activeId={activeId}
                onChange={id => setActiveId(id)}
                headerTitleType="border-bottom"
            />
            {/* <button className={styles.playAction} onClick={handlePlayAudio}>
                Play
            </button> */}
            <button className={styles.resetAction} onClick={() => setActiveId(sentenceList[0].id)}>
                Reset
            </button>
            <button className={styles.nextAction} onClick={handleNext}>
                Next
            </button>
            {activeId === 'background' ? (
                <div className={styles.viewportBg}>
                    <img src={bg} />
                    <h2>50 câu tiếng anh thông dụng nhất</h2>
                </div>
            ) : (
                <div className={styles.viewport}>
                    {_.map(data(), (i: SentenceType) => {
                        return activeId === i.name ? <Sentence audio={audio[i.name]} data={i.data} /> : null;
                    })}
                </div>
            )}
        </div>
    );
}

export default TypeAndVoice;
