import _ from 'lodash';

import Template from './Template';
import data from './data';

function Paragraph() {
    return (
        <div>
            {_.map(data, d => (
                <>
                    <Template {...d} />
                    <br />
                </>
            ))}
        </div>
    );
}

export default Paragraph;
