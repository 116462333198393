import axios from 'axios';
import Box from 'qnb-ui/src/components/Box';
import IconUserSVG from 'qnb-ui/src/components/Foundation/Icons/dist/foundation/User';
import IconButton from 'qnb-ui/src/components/IconButton';
import Popover from 'qnb-ui/src/components/Popover';
import { deleteCookie, getCookie } from 'qnb-ui/src/helpers/cookie';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styles from './ProfileSection.module.scss';

function ProfileSection() {
    const navigate = useNavigate();
    const baseAPI = useSelector(
        (state: { contentStore: ContentSpaceDataType }) => state.contentStore.baseAPI
    );

    const [showUser, setShowUser] = useState<boolean>(false);
    const user = useSelector((state: DataTypes) => state.contentStore.user);

    const logout = () => {
        axios
            .get(`${baseAPI}/user/logout`, {
                headers: {
                    Authorization: `Bearer ${getCookie('user')}`
                }
            })
            .then(() => {
                deleteCookie('user');
            })
            .catch(error => console.log(error));
    };

    const handleLogout = () => {
        logout();
        return navigate('/admin-login');
    };

    return (
        <Box>
            <Popover
                isOpen={showUser}
                positions={['bottom', 'left']} // preferred positions by priority
                containerStyle={{
                    backgroundColor: '#fff',
                    boxShadow: `0 6px 6px 0 rgba(54, 73, 84, 0.06), 0 0 18px 0 rgba(54, 73, 84, 0.12)`,
                    marginLeft: '-10px',
                    zIndex: '9999'
                }}
                // onClickOutside={() => setIsPopoverOpen(false)}
                content={({ nudgedLeft, nudgedTop }) => (
                    <div style={{ boxSizing: 'border-box', padding: '16px' }}>
                        <div onClick={handleLogout} style={{ cursor: 'pointer' }}>
                            Logout
                        </div>
                    </div>
                )}
            >
                <div>
                    <IconButton className={styles.notifi} onClick={() => setShowUser(!showUser)}>
                        <IconUserSVG />
                    </IconButton>
                </div>
            </Popover>
        </Box>
        //
    );
}

export default ProfileSection;
