import _ from 'lodash';

export type SettingsProps = {
    isAuto?: boolean;
    isAutoMp3?: boolean;
    toggleAutoplay?: () => void;
    toggleAutoMp3?: () => void;
};

function Settings(props: SettingsProps) {
    const { isAuto, isAutoMp3, toggleAutoplay, toggleAutoMp3 } = props;

    const handleAutoPlay = () => _.isFunction(toggleAutoplay) && toggleAutoplay();

    const handleAutoMP3 = () => _.isFunction(toggleAutoMp3) && toggleAutoMp3();

    return (
        <div className="_settings">
            <span>
                Auto Slide:
                <input type="checkbox" onChange={handleAutoPlay} checked={isAuto} />
            </span>
            <span id="auto-mp3-action" className="auto-mp3-actions">
                Auto MP3:
                <input type="checkbox" onChange={handleAutoMP3} checked={isAutoMp3} />
            </span>
        </div>
    );
}

export default Settings;
