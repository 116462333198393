// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZJGYJ6ywUr1_WzI4yx_c button{min-width:25px;padding:0 2px;margin-left:15px}`, "",{"version":3,"sources":["webpack://./src/views/backend/components/ProfileSection/ProfileSection.module.scss"],"names":[],"mappings":"AAIA,6BACI,cAAA,CACA,aAAA,CACA,gBAAA","sourcesContent":[".root {\n\n}\n\n.root :global(button) {\n    min-width: 25px;\n    padding: 0 2px;\n    margin-left: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ZJGYJ6ywUr1_WzI4yx_c`
};
export default ___CSS_LOADER_EXPORT___;
