import { toggleSidebar } from '@stores/ReduxStore';
import _ from 'lodash';
import IconMenu from 'qnb-ui/src/components/Foundation/Icons/dist/foundation/Menu';
import Heading from 'qnb-ui/src/components/Heading';
import IconButton from 'qnb-ui/src/components/IconButton';
import { __ } from 'qnb-ui/src/helpers/i18n';
import { useDispatch } from 'react-redux';

import LogoSection from '../../components/LogoSection';
import ProfileSection from '../../components/ProfileSection';
import SearchSection from '../../components/SearchSection';
import styles from './Header.module.scss';

function Header() {
    const dispatch = useDispatch();
    // const { width } = useWindowResize();

    // Call when width change
    // useMemo(() => {
    //     if (!_.isUndefined(width)) {
    //         width < 775 && dispatch(toggleSidebar(false));
    //         width > 1400 && dispatch(toggleSidebar(true));
    //     }
    // }, [dispatch, width]);

    const handleToggleSidebar = () => {
        dispatch(toggleSidebar());
    };

    return (
        <div className={styles.root}>
            <div className={styles.headerLeft}>
                <div className={styles.companyLogo}>
                    <LogoSection />
                    <Heading className={styles.mainTitleProject} as="h3">
                        {__('Mina')}
                    </Heading>
                </div>
                <IconButton onClick={handleToggleSidebar}>
                    <IconMenu size="small" />
                </IconButton>
            </div>
            <div className={styles.headerRight}>
                <SearchSection />
                <ProfileSection />
            </div>
        </div>
    );
}

export default Header;
