import cx from 'classnames';
import _ from 'lodash';
import Heading from 'qnb-ui/src/components/Heading';
import Text from 'qnb-ui/src/components/Text';
import { useEffect, useRef, useState } from 'react';

import { type Example, type WordType } from '../../data/types';
import { windowSpeak } from '../../helpers';
import styles from './Card.module.scss';

export type CardProps = WordType & {
    slug: string;
};

export type ShowMeanType = {
    index: number;
    isShow: boolean;
};

function SoundIcon(props: { onClick?: () => void }) {
    return (
        <span className={styles.sound} onClick={props.onClick}>
            <svg viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
                <path d="M8.74898127,16 L5,16 C4.44771525,16 4,15.5522847 4,15 L4.00000052,14.9989818 L4.00509158,9.99898179 C4.00565351,9.44709492 4.4532039,9 5.00509106,9 L8.74898127,9 L13.0061088,6 L14.0061088,6 L14.0061088,19 L12.9989813,19 L8.74898127,16 Z M5.00610875,10 L4.99898127,15 L7.99898127,15 L7.99898127,10 L5.00610875,10 Z M8.99898127,10 L8.99898127,15 L12.9989813,17.75 L12.9989813,7.25 L8.99898127,10 Z M17.775454,6.58117085 C19.6770121,7.60430712 20.9989813,9.86970302 20.9989813,12.5 C20.9989813,15.130297 19.6770121,17.3956929 17.775454,18.4188292 L17.413844,17.4786431 C18.9415538,16.5993687 19.9989813,14.7006138 19.9989813,12.5 C19.9989813,10.2993862 18.9415538,8.40063128 17.413844,7.5213569 L17.775454,6.58117085 L17.775454,6.58117085 Z M16.6844602,9.41775475 C17.4671493,10.0090242 17.9989813,11.1675609 17.9989813,12.5 C17.9989813,13.8324391 17.4671493,14.9909758 16.6844602,15.5822452 L16.3085797,14.6049558 C16.7238594,14.1603608 16.9989813,13.3837288 16.9989813,12.5 C16.9989813,11.6162712 16.7238594,10.8396392 16.3085797,10.3950442 L16.6844602,9.41775475 L16.6844602,9.41775475 Z" />
            </svg>
        </span>
    );
}

const ExampleItem = (item: Example) => {
    const { mean, sentence } = item;
    const [showMean, setShowMean] = useState<boolean>(false);

    useEffect(() => {
        let timer: any;
        if (showMean) {
            timer = setTimeout(() => {
                setShowMean(false);
            }, 3000);
        }

        return () => clearTimeout(timer);
    }, [showMean]);

    return (
        <Text className={cx(styles.example, { showMean })} onClick={() => setShowMean(!showMean)}>
            - {showMean ? mean : sentence}
        </Text>
    );
};

function Card(props: CardProps) {
    const { word, pronUK, pronUS, thumbnail, slug, types } = props;
    const refItem = useRef(null);

    const handleOpenUKMp3 = () => windowSpeak('en-GB', word);
    const handleOpenUSMp3 = () => windowSpeak('en-US', word);

    return (
        <div className={cx(styles.wrapper, 'wrapper')} ref={refItem} id={slug}>
            <div className={styles.container}>
                {thumbnail && (
                    <div className={styles.thumbnail}>
                        <img src={thumbnail} alt={word} />
                    </div>
                )}
                <div className={styles.content}>
                    <Heading
                        as="h3"
                        className={cx(styles.content__label, `${_.get(types, '0.typeName', '')}`)}
                    >
                        {word}
                    </Heading>
                    <div className={styles.content__info}>
                        <div className={styles.content__info_pron}>
                            <span className={styles.pronAudio}>
                                <Text weight="bold">UK</Text>
                                <SoundIcon onClick={handleOpenUKMp3} />
                                <Text style={{ fontFamily: 'Arial' }}>{pronUK}</Text>
                            </span>
                            <span className={styles.pronAudio}>
                                <Text weight="bold">US</Text>
                                <SoundIcon onClick={handleOpenUSMp3} />
                                <Text style={{ fontFamily: 'Arial' }}>{pronUS}</Text>
                            </span>
                        </div>
                        <div className={styles.contentInfoMean}>
                            {_.map(types, (type, index) => {
                                return (
                                    <div className={styles.infoBlog} key={index}>
                                        <Text className={cx(styles.mean)}>
                                            [
                                            {
                                                <Text
                                                    className={cx(styles.type, `${type.typeName}`)}
                                                    skin="error"
                                                    as="span"
                                                >
                                                    {type.typeName}
                                                </Text>
                                            }
                                            ]: {type.mean}
                                        </Text>
                                        <div className={styles.examples}>
                                            <Text size="tiny">
                                                <Text weight="bold" as="span">
                                                    Ex:
                                                </Text>
                                                {_.get(type, `ex[0]`) && (
                                                    <ExampleItem
                                                        mean={_.get(type, `ex[0].mean`, ``)}
                                                        sentence={_.get(type, `ex[0].sentence`, ``)}
                                                    />
                                                )}
                                                {_.get(type, `ex[1]`) && (
                                                    <ExampleItem
                                                        mean={_.get(type, `ex[1].mean`, ``)}
                                                        sentence={_.get(type, `ex[1].sentence`, ``)}
                                                    />
                                                )}
                                            </Text>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Card;
