import Search from 'qnb-ui/src/components/Search';
import React from 'react';

function SearchSection() {
    return (
        <div>
            <Search />
        </div>
    );
}

export default SearchSection;
