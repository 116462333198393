import { Autocomplete, TextField } from '@mui/material';
import _ from 'lodash';
import { useMemo } from 'react';

export type ListProps = {
    title: string;
    text1: string;
    text2: string;
};

export type OwnProps = {
    initData: ListProps[];
};

function ListBase(props: OwnProps) {
    const { initData } = props;

    const options = useMemo(() => {
        const newData: string[] = [];

        _.each(initData, value => {
            newData.push(value.title);
        });

        return newData;
    }, [initData]);

    return (
        <Autocomplete
            options={options}
            renderInput={params => <TextField {...params} label="Search Topic" />}
        />
    );
}

export default ListBase;
