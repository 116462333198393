import axios from 'axios';
import cx from 'classnames';
import Button from 'qnb-ui/src/components/Button';
import FileUpload from 'qnb-ui/src/components/FileUpload';
import IconUploadExport from 'qnb-ui/src/components/Foundation/Icons/dist/foundation/UploadExport';
import Heading from 'qnb-ui/src/components/Heading';
import Input from 'qnb-ui/src/components/Input';
import Text from 'qnb-ui/src/components/Text';
import { getCookie, setCookie } from 'qnb-ui/src/helpers/cookie';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './CreateNewCategory.module.scss';

const toBase64 = (files: FileList) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
    });

function CreateNewCategory() {
    const [title, setTitle] = useState<string>('');
    const [thumbnail, setThumbnail] = useState<FileList>();
    const [description, setDescription] = useState<string>('');

    const baseAPI = useSelector(
        (state: { contentStore: ContentSpaceDataType }) => state.contentStore.baseAPI
    );

    const handleSubmit = async e => {
        e.preventDefault();
        if (getCookie('user')) {
            const thumbnailBase64 = thumbnail && (await toBase64(thumbnail));

            axios
                .post(
                    `${baseAPI}/category/create`,
                    {
                        title,
                        thumbnail: thumbnailBase64,
                        description
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${getCookie('user')}`
                        }
                    }
                )
                .then(result => console.log(result))
                .catch(error => console.log(error));
        }
    };

    return (
        <div className={cx(styles.root, 'container')}>
            <Heading>Create new Category</Heading>
            <form action="" onSubmit={handleSubmit}>
                <div className="form-control">
                    <Text>Title</Text>
                    <Input name="title" value={title} onChange={(value: string) => setTitle(value)} />
                </div>
                <div className="form-control">
                    <Text>Thumbnail</Text>
                    <FileUpload
                        //@ts-expect-error
                        prefix={<IconUploadExport />}
                        file={thumbnail}
                        onChange={(file: FileList) => file && setThumbnail(file)}
                    />
                </div>
                <div className="form-control">
                    <Text>Description</Text>
                    <Input
                        name="title"
                        value={description}
                        onChange={(value: string) => setDescription(value)}
                    />
                </div>
                <div className="form-control">
                    <Button type="submit">Create</Button>
                </div>
            </form>
        </div>
    );
}

export default CreateNewCategory;
