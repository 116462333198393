import { useEffect, useState } from 'react';
import TypeIt from 'typeit-react';

import styles from './ThreeYear.module.scss';
import { initHeart } from './helper';

function ThreeYear() {
    const [showLove, setShowLove] = useState(false);

    const getLove = () => {
        return (
            <span className={styles.typeLove}>
                <TypeIt
                    options={{
                        waitUntilVisible: true,
                        cursorChar: '♡',
                        cursor: {
                            animation: {
                                options: { duration: 500 }
                            }
                        }
                    }}
                    getBeforeInit={instance => {
                        instance.pause(750).type('<span style="font-size: 34px">Yêu em </span>');
                        return instance;
                    }}
                />
            </span>
        );
    };

    useEffect(() => {
        setInterval(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 200);
    }, []);

    return (
        <>
            <div className={styles.root}>
                <TypeIt
                    options={{
                        waitUntilVisible: true,
                        cursorChar: '♡',
                        cursor: {
                            animation: {
                                options: { duration: 500 }
                            }
                        },
                        afterComplete: () => {
                            initHeart();
                            setShowLove(true);
                        }
                    }}
                    getBeforeInit={instance => {
                        instance
                            .type('Hi Mina, em bé của anh!')
                            .pause(900)
                            .delete(16)
                            .pause(500)
                            .type(`, I'm Mark!`)
                            .break()
                            .type(
                                `Mới đó chúng ta đã bên nhau 3 năm, với nhiều kỷ niệm, có cả niềm vui, cả nỗi buồn, nhưng điều quan trọng là chúng ta vẫn bên nhau.`
                            )
                            .break()
                            .type(
                                `Nhìn lại những ngày tháng bên em như một bức tranh thật đẹp, được vẽ lên bằng sự hiểu biết, tôn trọng và tình cảm chân thành. Sự yêu thương và quan tâm của em đã giúp anh vượt qua rất nhiều khó khăn và dần trưởng thành hơn trong cuộc sống.`
                            )
                            .break()
                            .pause(500)
                            .type(
                                `Em đến bên anh, bước vào cuộc sống của anh, mang đến cho nó sức sống và thêm nhiều màu sắc hơn.`
                            )
                            .pause(200)
                            .break()
                            .type(
                                `Cảm ơn em luôn bên cạnh anh những lúc vui, những lúc buồn, ngay cả những khó khăn cũng luôn có em bên cạnh.`
                            )
                            .break()
                            .type(
                                `Hy vọng rằng chúng ta sẽ tiếp tục xây dựng những kỷ niệm đáng nhớ và trải qua những chặng đường mới trong cuộc sống.`
                            )
                            .pause(200)
                            .break()
                            .type(`Cuối cùng anh có món quà nhỏ này tặng em:`)
                            .pause(500);

                        // Remember to return it!
                        return instance;
                    }}
                />

                {showLove && getLove()}
            </div>
            <canvas className={styles.heart} id="heart"></canvas>
        </>
    );
}

export default ThreeYear;
