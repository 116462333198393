import { Button, TextField } from '@mui/material';
import _ from 'lodash';
import { useState } from 'react';

/** 
 * Example data:
1
=
large
;
2
=
False
;
3
=
True
;
 */

function ExamScoring() {
    const [text1, setText1] = useState<string>('');
    const [count, setCount] = useState<number[]>([]);
    const [text2, setText2] = useState<string>('');
    const [result, setResult] = useState<any[]>([]);
    const [error, setError] = useState<any[]>([]);

    const handleChangeText1 = (value: string) => {
        setText1(value);
    };

    const handleChangeText2 = (value: string) => {
        setText2(value);
    };

    const computed = () => {
        const splitText = (text: string) => {
            const abc = {};
            const c = text.split(';');
            _.each(c, i => {
                const a = i.split('=');
                if (_.trim(a[0]) !== '') abc[_.trim(a[0])] = _.trim(a[1]).toLowerCase();
            });

            return abc;
        };

        if (text1 !== '' && text2 !== '') {
            const a1 = splitText(text1);
            const a2 = splitText(text2);

            let rl: any[] = [];
            let er: any[] = [];
            console.log('_.size(a1)', _.size(a1));

            setCount([_.size(a1), _.size(a2)]);
            _.each(a2, (value, index) => {
                if (_.toString(_.get(a1, index)) === _.toString(value)) {
                    rl.push({ key: index, value });
                } else {
                    er.push({ key: index, value1: a1[index], value2: a2[index] });
                }
            });
            setResult(rl);
            setError(er);
        }
    };

    const handleSubmit = () => computed();

    return (
        <div
            style={{
                width: '500px',
                margin: '50px auto'
            }}
        >
            <div
                style={{
                    width: '500px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px'
                }}
            >
                <TextField
                    id="text1"
                    label="Đáp án"
                    fullWidth
                    value={text1}
                    onChange={e => handleChangeText1(e.target.value)}
                />
                <TextField
                    id="text2"
                    label="Câu trả lời"
                    fullWidth
                    value={text2}
                    onChange={e => handleChangeText2(e.target.value)}
                />

                <Button variant="contained" onClick={handleSubmit}>
                    Submit
                </Button>
            </div>
            <br />
            {result.length ? (
                <div>
                    Result: {result.length} / {count[0]}
                </div>
            ) : null}
            <br />
            {error.length ? (
                <div>
                    Errors:
                    {_.map(error, i => {
                        return (
                            <div>
                                {i?.key} : {i?.value2 ? i?.value2 : '[empty]'} - ({i.value1})
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
}

export default ExamScoring;
