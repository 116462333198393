import _ from 'lodash';
import Heading from 'qnb-ui/src/components/Heading';
import Text from 'qnb-ui/src/components/Text';
import Slider from 'react-slick';

import audio from './audio.mp3';

export type SlideProps = {
    title?: string;
    text?: string | React.ReactNode;
    textStyle?: React.CSSProperties;
    author?: string;
    bgLink?: string;
};

const Slide = (props: SlideProps) => {
    const { title, text, textStyle, author, bgLink } = props;
    return (
        <div
            style={{
                width: '482px',
                margin: 'auto',
                height: '856px',
                backgroundImage: `url(${bgLink})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '42px'
            }}
        >
            <div>
                {title && (
                    <Heading
                        style={{
                            fontSize: '42px',
                            lineHeight: '48px',
                            color: '#fff',
                            textTransform: 'uppercase',
                            textAlign: 'center'
                        }}
                    >
                        {title}
                    </Heading>
                )}
                {text && (
                    <Text
                        style={{
                            fontSize: '28px',
                            lineHeight: '38px',
                            color: '#fff',
                            ...textStyle
                        }}
                    >
                        {text}
                    </Text>
                )}
                {author && (
                    <Text
                        textAlign="right"
                        style={{
                            color: '#fff'
                        }}
                    >
                        - {author} -
                    </Text>
                )}
            </div>
        </div>
    );
};

const data: SlideProps[] = [
    {
        title: 'Khi mất động lực, hãy xem nó',
        bgLink: 'https://i.pinimg.com/736x/a2/1d/42/a21d4286c14435820897fc2159233745.jpg'
    },
    {
        text: `1. Đừng cố gắng tỏa sáng. Hãy là chính mình và làm những gì mình thích.`,
        author: 'Warren Buffett',
        textStyle: {},
        bgLink: `https://i.pinimg.com/736x/6b/c9/d1/6bc9d1c4b7eb68b98c57b3b69cb6ae19.jpg`
    },
    {
        text: `2. Nếu bạn muốn có được những thứ bạn chưa từng có thì bạn phải làm những việc bạn chưa từng làm.`,
        author: 'Sưu tầm',
        bgLink: `https://i.pinimg.com/736x/7e/0b/ca/7e0bcaad983d44ee15c7239f4a991845.jpg`
    },
    {
        text: `3. Đừng làm mòn giá trị của bản thân bằng việc so sánh với người khác.`,
        author: 'Sưu tầm',
        bgLink: `https://i.pinimg.com/736x/37/d3/cd/37d3cd5ed12d4c0f747d67742b9ac087.jpg`
    },
    {
        text: (
            <span>
                4. Nếu bạn sinh ra trong nghèo khó, đó không phải là lỗi của bạn.
                <br />
                Nhưng nếu bạn chết trong nghèo khó, thì đó là lỗi của bạn.
            </span>
        ),
        author: 'Bill Gates',
        bgLink: `https://i.pinimg.com/736x/06/e8/8d/06e88d5c351a20a29944aa5b57900668.jpg`
    },
    {
        text: `5. Cuộc sống luôn cho ta một cơ hội thứ hai, nó được gọi là "ngày mai".`,
        author: 'Sưu tầm',
        bgLink: `https://i.pinimg.com/736x/9c/63/8e/9c638e5970cd7e8086ccb9d61239cff4.jpg`
    }
];
function TypeAndVoice() {
    var settings = {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };

    return (
        <div
            style={{
                width: '482px',
                margin: 'auto'
            }}
        >
            <audio controls src={audio} />
            <br />
            <Slider {...settings}>
                {_.map(data, i => (
                    <Slide {...i} />
                ))}
            </Slider>
        </div>
    );
}

export default TypeAndVoice;
