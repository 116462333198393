import { Button, Stack, TextField } from '@mui/material';
import axios from 'axios';
import { COPYRIGHT_AUTHOR, COPYRIGHT_ICON, COPYRIGHT_YEAR } from 'constants/app';
import { SUCCESS_MESSAGE } from 'constants/status';
import $ from 'jquery';
import _ from 'lodash';
import Heading from 'qnb-ui/src/components/Heading';
import Text from 'qnb-ui/src/components/Text';
import { useEffect, useRef, useState } from 'react';

import List from './List';
import { ListProps } from './List/container';
import UploadFile from './UploadFile';
import audio_bg from './audio_bg.mp3';

export type SlideProps = {
    title?: string;
    text?: string | React.ReactNode;
    textStyle?: React.CSSProperties;
    author?: string;
    bgLink?: string;
};

export type Value = { title?: string; text1?: string; text2?: string };

export type OwnProps = {
    theme: 'light' | 'dark';
};

const Host = 'https://server.mina-corner.com';
// const Host = 'http://localhost:5602';

const formatData = (data: string) => {
    const newArr: ListProps[] = [];
    const splitItems = data.split('},');
    _.each(splitItems, i => {
        if (i !== '') {
            const item = {};
            _.each(i.trim().substring(2).split(`","`), j => {
                const temp = j.split(':');
                if (temp.length === 2) {
                    item[temp[0].substring(0, temp[0].length - 1)] = temp[1].substring(1).trim();
                }
            });
            if (!_.isEmpty(item)) newArr.push(item as ListProps);
        }
    });

    return newArr;
};

function ReadingAndListening(props: OwnProps) {
    const { theme = 'dark' } = props;
    const container = useRef(null);
    const [title, setTitle] = useState<string>('');
    const [text1, setText1] = useState<string>('');
    const [text2, setText2] = useState<string>('');
    const [initData, setInitData] = useState<any[]>([]);
    const [audio, setAudio] = useState<File | undefined>();

    const [play, setPlay] = useState<boolean>(false);

    /** Get list data post */
    useEffect(() => {
        axios
            .get(`${Host}/english/reading-listening/read`, {})
            .then(data => {
                const newData = formatData(data.data);
                setInitData(newData);
            })
            .catch(err => console.log('err', err));
    }, []);

    /** Post data form */
    const handleSubmitData = () => {
        if (title !== '' && text1 !== '' && text2 !== '') {
            axios
                .post(`${Host}/english/reading-listening/write`, {
                    data: { title, text1, text2 }
                })
                .then(result => {
                    if (result.data?.message === SUCCESS_MESSAGE)
                        axios
                            .get(`${Host}/english/reading-listening/read`, {})
                            .then(data => {
                                const newData = formatData(data.data);
                                setInitData(newData);
                            })
                            .catch(err => console.log('err', err));
                })
                .catch(err => console.log('err', err));
        }
    };

    const handleChangeAudio = (file: File) => setAudio(file);

    useEffect(() => {
        const audio = document.getElementById('audio_mp3') as HTMLVideoElement;
        const audio_bg = document.getElementById('audio_bg_mp3') as HTMLVideoElement;
        if (container.current && audio) {
            const element = $(container.current);
            if (play) {
                audio?.play();
                audio_bg?.play();

                const scrollHeight = element.prop('scrollHeight');

                element.animate(
                    {
                        scrollTop: scrollHeight
                    },
                    Math.ceil(scrollHeight / 856) * 22120 /** 5s / 1 page  */,
                    'linear'
                );
            } else {
                audio?.pause();
                audio_bg?.pause();
                element.stop();
                element.scrollTop(0);
            }
        }
    }, [play]);

    /** Play audio and scroll */
    const handlePlay = () => setPlay(true);

    /** Reset */
    const handleReset = () => setPlay(false);

    const handleChangeTitle = (value: string, type: 'title' | 'text1' | 'text2') => {
        if (type === 'title') setTitle(value);
        else if (type === 'text1') setText1(value);
        else if (type === 'text2') setText2(value);
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row'
            }}
        >
            <Stack
                gap={3}
                style={{
                    width: '500px'
                }}
            >
                <Stack gap={2}>
                    <TextField
                        id="title"
                        label="Title"
                        fullWidth
                        required
                        value={title}
                        onChange={e => handleChangeTitle(e.target.value, 'title')}
                    />
                    <TextField
                        id="text1"
                        label="Text 01"
                        fullWidth
                        required
                        value={text1}
                        onChange={e => handleChangeTitle(e.target.value, 'text1')}
                    />
                    <TextField
                        id="text2"
                        label="Text 02"
                        fullWidth
                        required
                        value={text2}
                        onChange={e => handleChangeTitle(e.target.value, 'text2')}
                    />
                    <UploadFile onChange={handleChangeAudio} fileName={audio?.name} />
                    <Button onClick={handleSubmitData} variant="contained">
                        Push Data
                    </Button>
                </Stack>
                <Stack
                    sx={{
                        margin: '20px 0',
                        padding: '20px 0',
                        borderTop: '1px dotted #333'
                    }}
                >
                    <Text weight="bold">Day: {initData.length}</Text>
                    <List initData={initData} />
                </Stack>
            </Stack>
            <div
                style={{
                    width: 'calc(482px + 15px)',
                    margin: 'auto'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        gap: '10px'
                    }}
                >
                    <button style={{ width: '75px' }} onClick={handlePlay}>
                        Play
                    </button>
                    <button style={{ width: '75px' }} onClick={handleReset}>
                        Reset
                    </button>
                    <audio id="audio_mp3" controls src={audio ? URL.createObjectURL(audio) : ''} />
                    <audio id="audio_bg_mp3" controls src={audio_bg} />
                </div>
                <br />
                <div
                    id="okkkkk"
                    ref={container}
                    style={{
                        width: '100%',
                        height: '856px',
                        overflow: 'auto',
                        backgroundColor: theme === 'light' ? '#fff' : '#222'
                    }}
                >
                    <div
                        id="qqqqqq"
                        style={{
                            width: '100%',
                            height: 'fit-content',
                            paddingRight: '15px'
                        }}
                    >
                        <div
                            className="content"
                            style={{
                                padding: '40px',
                                paddingTop: '300px',
                                paddingBottom: '850px'
                            }}
                        >
                            <Heading
                                style={{
                                    color: theme === 'light' ? '#222' : '#fff',
                                    fontSize: '36px',
                                    lineHeight: '46px',
                                    textAlign: 'center'
                                }}
                            >
                                {title}
                            </Heading>
                            <br />
                            <br />
                            <Text
                                style={{
                                    fontSize: '34px',
                                    lineHeight: '46px',
                                    textAlign: 'center',
                                    color: theme === 'light' ? '#222' : '#fff'
                                }}
                            >
                                {text1}
                            </Text>
                            <br />
                            <Text
                                style={{
                                    fontSize: '34px',
                                    lineHeight: '46px',
                                    textAlign: 'center',
                                    color: theme === 'light' ? '#222' : '#fff'
                                }}
                            >
                                {text2}
                            </Text>
                            <br />
                            <br />
                            <br />
                            <Text
                                style={{
                                    fontSize: '12px',
                                    lineHeight: '26px',
                                    textAlign: 'center',
                                    color: theme === 'light' ? '#222' : '#fff'
                                }}
                            >
                                {COPYRIGHT_ICON} {COPYRIGHT_YEAR} {COPYRIGHT_AUTHOR}
                            </Text>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReadingAndListening;
