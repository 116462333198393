import { type WordType } from '../../../types';

const clearance: WordType = {
    word: `clearance`,
    pronUK: `/ˈklɪə.rəns/`,
    pronUS: `/ˈklɪr.əns/`,
    types: [
        {
            typeName: `noun`,
            mean: `Giải toả, khoảng trống, bán hạ giá`,
            ex: [
                {
                    sentence: `Much of the area was bulldozed into oblivion in a wave of slum clearances.`,
                    mean: `Phần lớn diện tích đã bị san phẳng trong làn sóng giải phóng mặt bằng khu ổ chuột.`
                }
            ]
        }
    ]
};

export default clearance;
