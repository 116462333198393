import TypeIt from 'typeit-react';

import styles from './Style.module.scss';

export type SentenceProps = {
    audio: string;
    data: string;
};

/** What's your name */
function Sentence(props: SentenceProps) {
    const { audio, data } = props;
    const initData = data.split(' <=> ');

    return (
        <>
            <div className={styles.root}>
                <div className={styles.controlAudio}>
                    <audio controls src={audio} />
                </div>

                <div className={styles.wrapper}>
                    <TypeIt
                        options={{
                            waitUntilVisible: true,
                            speed: 100,
                            cursor: {
                                animation: {
                                    options: { duration: 500 }
                                }
                            },
                            afterComplete: () => {}
                        }}
                        getBeforeInit={instance => {
                            instance
                                .pause(1000)
                                .type(`<span style="font-weight: bold">${initData[0]}</span>`)
                                .break()
                                .type(
                                    `<span style="color: #ffcc00; font-family: sans-serif">${initData[1]}<span>`
                                )
                                .break()
                                .type(`${initData[2]}`);

                            // Remember to return it!
                            return instance;
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default Sentence;
