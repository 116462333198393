import { configureStore } from '@reduxjs/toolkit';
import mainStore from './ReduxStore';
import contentStore from './ContentStore';

export const initStore = () =>
    configureStore({
        reducer: { mainStore, contentStore }
    });

const store = initStore();

export type RootState = ReturnType<typeof store.getState>;

export default store;
