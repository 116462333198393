import styles from './ImageItem.module.scss';

function ImageItem(props: { url?: string }) {
    const { url } = props;
    return (
        <div className={styles.root}>
            <a
                className={styles.linkImage}
                href="/#"
                style={{
                    backgroundImage: `url("${url}")`
                }}
            />
        </div>
    );
}

export default ImageItem;
