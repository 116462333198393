// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./black.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Jo2bqZpdABtceqsT3wUe{width:100%;min-height:100vh;background-color:#000;color:#fff;font-size:1.1em;position:relative}.gBZp5UPhqXHtUauriJxW{position:absolute;width:50px;height:30px;top:68px;left:80px;cursor:pointer}.AcMrjW9wPjeLg2lW69E0{position:absolute;width:50px;height:30px;top:68px;left:10px;cursor:pointer}._LaAZeoCxjQJxycHRFQ6,.XbXZxYnkqIb4_xxbnWHn{position:relative;width:1800px;margin-top:30px;height:1220px;display:flex;justify-content:center;align-items:center;transform:scale(0.6) translate(-349px, -350px);background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-size:cover;background-repeat:no-repeat}`, "",{"version":3,"sources":["webpack://./src/views/frontend/English/TypeAndVoice/TypeAndVoice.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,gBAAA,CACA,qBAAA,CACA,UAAA,CACA,eAAA,CACA,iBAAA,CAGJ,sBACI,iBAAA,CACA,UAAA,CACA,WAAA,CACA,QAAA,CACA,SAAA,CACA,cAAA,CAGJ,sBACI,iBAAA,CACA,UAAA,CACA,WAAA,CACA,QAAA,CACA,SAAA,CACA,cAAA,CAGJ,4CAEI,iBAAA,CACA,YAAA,CACA,eAAA,CACA,aAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,8CAAA,CACA,wDAAA,CACA,qBAAA,CACA,2BAAA","sourcesContent":[".root {\n    width: 100%;\n    min-height: 100vh;\n    background-color: #000;\n    color: #fff;\n    font-size: 1.1em;\n    position: relative;\n}\n\n.nextAction {\n    position: absolute;\n    width: 50px;\n    height: 30px;\n    top: 68px;\n    left: 80px;\n    cursor: pointer;\n}\n\n.resetAction {\n    position: absolute;\n    width: 50px;\n    height: 30px;\n    top: 68px;\n    left: 10px;\n    cursor: pointer;\n}\n\n.viewportBg,\n.viewport {\n    position: relative;\n    width: 1800px; \n    margin-top: 30px;\n    height: 1220px;\n    display: flex;\n    justify-content: center;\n    align-items: center; \n    transform: scale(0.6) translate(-349px, -350px);\n    background-image: url(\"./black.jpeg\");\n    background-size: cover;\n    background-repeat: no-repeat;\n}\n\n.viewportBg {\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Jo2bqZpdABtceqsT3wUe`,
	"nextAction": `gBZp5UPhqXHtUauriJxW`,
	"resetAction": `AcMrjW9wPjeLg2lW69E0`,
	"viewportBg": `_LaAZeoCxjQJxycHRFQ6`,
	"viewport": `XbXZxYnkqIb4_xxbnWHn`
};
export default ___CSS_LOADER_EXPORT___;
