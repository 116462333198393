import menuItems from '@routers/menu-pages';
import { changePageActived, toggleSidebar } from '@stores/ReduxStore';
import axios from 'axios';
import cx from 'classnames';
import Container from 'qnb-ui/src/components/Container';
import { getCookie, setCookie } from 'qnb-ui/src/helpers/cookie';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import styles from './Backend.module.scss';
import './Backend.scss';

function MainLayout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sidebarOpen = useSelector((state: { mainStore: InitDataType }) => state.mainStore.sidebarOpen);
    const currentPageActived = useSelector(
        (state: { mainStore: InitDataType }) => state.mainStore.currentPageActived
    );
    const baseAPI = useSelector(
        (state: { contentStore: ContentSpaceDataType }) => state.contentStore.baseAPI
    );

    useEffect(() => {
        if (getCookie('user')) {
            axios
                .post(
                    `${baseAPI}/user/loginWithToken`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${getCookie('user')}`
                        }
                    }
                )
                .then(
                    (result: {
                        status?: number;
                        data?: {
                            message?: string;
                        };
                    }) => {
                        if (result.data?.message === 'Logged') {
                            return true;
                        }
                        return navigate('/admin-login');
                    }
                )
                .catch(() => navigate('/admin-login'));
        } else {
            return navigate('/admin-login');
        }
    }, [navigate, baseAPI]);

    const handleChangePage = (url?: string) => {
        url && dispatch(changePageActived({ url }));
    };

    const handleToggleSidebar = (status?: boolean) => {
        dispatch(toggleSidebar(status));
    };

    return (
        <div className={cx(styles.root, { 'side-collapsed': !sidebarOpen }, '_backend')}>
            <Header />
            <Sidebar
                menu={menuItems}
                sidebarOpen={sidebarOpen}
                pageChange={handleChangePage}
                currentPageActived={currentPageActived}
                toggleSidebar={handleToggleSidebar}
            />
            <Container className={styles.mainContent} isFluid>
                <Outlet />
            </Container>
        </div>
    );
}

export default MainLayout;
