// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Rp8UWn4QDGXqa2I7Rm5Q{display:flex;flex-direction:column;gap:60px}`, "",{"version":3,"sources":["webpack://./src/views/frontend/components/SidebarRight/SidebarRight.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,QAAA","sourcesContent":[".root { \n    display: flex;\n    flex-direction: column;\n    gap: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Rp8UWn4QDGXqa2I7Rm5Q`
};
export default ___CSS_LOADER_EXPORT___;
