import { type WordType } from '../../../types';

const follow: WordType = {
    word: `follow`,
    pronUK: `/ˈfɒl.əʊ/`,
    pronUS: `/ˈfɑː.loʊ/`,
    types: [
        {
            typeName: `verb`,
            mean: `Theo, theo sau`,
            ex: [
                {
                    sentence: `A dog followed us home.`,
                    mean: `Con chó theo chúng tôi về nhà`
                },
                {
                    sentence: `She followed me into the kitchen.`,
                    mean: `Cô ấy theo tôi vào bếp`
                }
            ]
        }
    ]
};

export default follow;
