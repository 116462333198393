import { type WordType } from '../../../types';

const refer: WordType = {
    word: `refer`,
    pronUK: `/rɪˈfɜːr/`,
    pronUS: `/rɪˈfɝː/`,
    types: [
        {
            typeName: `noun`,
            mean: `Tham khảo, giới thiệu (ai đó)`,
            ex: [
                {
                    sentence: `After the operation, her liver failed and doctors referred her for an emergency transplant.`,
                    mean: `Sau ca phẫu thuật, gan của cô ấy bị hỏng và các bác sĩ đã chuyển cô ấy đi cấy ghép khẩn cấp.`
                },
                {
                    sentence: `Your doctor may refer you to a physiotherapist to help you with the exercises.`,
                    mean: `Các bác sĩ có thể giới thiệu bạn đến một nhà vật lý trị liệu để giúp bạn tập luyện.`
                }
            ]
        }
    ]
};

export default refer;
