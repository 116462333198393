// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RmU6MdBB9F_fTJ88tsAu{position:absolute;left:0;top:-75px}.On6JGPezgaIBr1nz_myW{width:100%;margin:auto;padding:10px;line-height:1.5em;font-family:"Raleway";text-align:center;font-size:80px}`, "",{"version":3,"sources":["webpack://./src/views/frontend/English/TypeAndVoice/Sentence/Style.module.scss"],"names":[],"mappings":"AAGA,sBACI,iBAAA,CACA,MAAA,CACA,SAAA,CAGJ,sBACI,UAAA,CACA,WAAA,CACA,YAAA,CACA,iBAAA,CACA,qBAAA,CACA,iBAAA,CACA,cAAA","sourcesContent":[".root { \n}\n\n.controlAudio {\n    position: absolute;\n    left: 0;\n    top: -75px;\n}\n\n.wrapper {\n    width: 100%;\n    margin: auto;\n    padding: 10px; \n    line-height: 1.5em;\n    font-family: \"Raleway\";\n    text-align: center;\n    font-size: 80px;\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlAudio": `RmU6MdBB9F_fTJ88tsAu`,
	"wrapper": `On6JGPezgaIBr1nz_myW`
};
export default ___CSS_LOADER_EXPORT___;
