import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { ActionTypes } from './ActionTypes';

const initState = {
    sidebarOpen: true,
    currentPageActived: ''
};

const AppStore = createSlice<InitDataType, ActionTypes>({
    name: 'app_name',
    initialState: initState as InitDataType,
    reducers: {
        toggleSidebar: (state, action) => {
            if (!_.isUndefined(action.payload)) state.sidebarOpen = action.payload;
            else state.sidebarOpen = !state.sidebarOpen;
        },
        initStore: (state, action) => {
            // state.title = action.payload.title;
        },
        changePageActived: (state, action) => {
            state.currentPageActived = action.payload.url;
        }
    }
});

export const { toggleSidebar, initStore, changePageActived } = AppStore.actions;

export default AppStore.reducer;
