import { TemplateProps } from '../Template';

const data: TemplateProps[] = [
    {
        title: `Scientists say early risers have Neanderthal genes`,
        text: [
            `Early risers naturally wake up early because of their body clock. Most people are still in deep sleep. Researchers said early risers share DNA with Neanderthals – our ancestors from 40,000 years ago. They lived in northern Europe and Asia. They woke up earlier to gather food as the sun rose. A researcher said our body clock can change with the light of different seasons. He said a "faster" body clock makes people "more likely to rise early".`,
            `The researchers looked at genetic information to find out why some people are early birds and others are night owls. The early birds shared DNA with Neanderthals. However, the effect of the Neanderthal DNA may be weakening as time passes. Our modern lifestyles mean many of us prefer to sleep in and not get up. Nevertheless, it may still be true that the early bird catches the worm.`
        ],
        audioKey: 'bkn_lv01_ls01'
    },
    {
        title: `Scientists 'talk' with a whale for 20 minutes`,
        text: [
            `Scientists have had a "chat" with a humpback whale. The scientists are from three different organizations. The whale is named Twain. They spoke to her in "humpback language" for 20 minutes. The scientists sent a "contact call" into the ocean. Twain heard the call. She then swam to the research boat and "chatted". A researcher said it was the first ever chat between humans and humpback whales.`,
            `The chat with Twain was like lots of greetings. Whales use contact calls so other whales know where they are. The scientists made 36 calls in 20 minutes. Twain answered each call by calling back at the same intervals. A scientist said her research could make it easier to talk to aliens. She said: "We can better understand what an alien intelligence might be like because they are not going to be exactly like ours."`
        ],
        audioKey: 'bkn_lv01_ls02'
    },
    {
        title: `Thinking in React`,
        text: [
            `React can change how you think about the designs you look at and the apps you build. When you build a user interface with React, you will first break it apart into pieces called components. Then, you will describe the different visual states for each of your components. Finally, you will connect your components together so that the data flows through them. In this tutorial, we’ll guide you through the thought process of building a searchable product data table with React.`,
            `You can use React without a framework, however we’ve found that most apps and sites eventually build solutions to common problems such as code-splitting, routing, data fetching, and generating HTML. These problems are common to all UI libraries, not just React.`
        ],
        audioKey: 'bkn_lv01_ls03'
    },
    {
        title: `Opportunity & Experience`,
        text: [
            `Learning English can open many doors and opportunities for you. It is a global language that allows you to communicate with people from all over the world. Whether you are traveling, working, or studying, knowing English can enhance your experiences and make them more enriching. Don't be afraid to make mistakes; they are a natural part of the learning process. Practice speaking, listening, reading, and writing every day, even if it's just for a few minutes. Join language groups, watch English movies, and listen to English songs to make learning fun and enjoyable. Remember, every small step you take brings you closer to fluency. Keep pushing yourself, stay motivated, and you will see great progress over time. Believe in yourself and your ability to learn. You can do it!`
        ],
        audioKey: 'dep_p01'
    },
    {
        title: `A Day at the Beach`,
        text: [
            `During the summer, Anna loves spending time at the beach. She enjoys swimming in the ocean and building sandcastles with her younger brother. Last weekend, her family packed a picnic and spent the whole day by the sea. They played volleyball, collected seashells, and relaxed under the sun. Anna’s favorite part of the day was watching the sunset. The sky turned beautiful shades of pink and orange, and it was a perfect end to a fun day.`
        ],
        audioKey: 'dep_p02'
    }
];

export default data;
