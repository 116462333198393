import _ from 'lodash';

// import * as lession1 from './01_Contracts';
// import * as lession2 from './02_Marketing';
// import * as lession3 from './03_Warranties';
import * as test_01 from './study4/test_01';

// export default [..._.map(words, word => word), ...initData];
// export default [..._.map(words1, (word, index) => _.merge(word, { id: index }))];

// const words = _.merge(lession3);
const words = _.merge(test_01);
// const words = _.merge(lession1, lession2, lession3);
export default words;
