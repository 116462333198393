import Navigation from '@components/Navigation';
import { pages } from '@routers/linkPages';
import $ from 'jquery';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import styles from './Page_01.module.scss';

function Home() {
    const [delta, setDelta] = useState(0);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const scrollThreshold = 40;
    const [numSlides, setNumSlides] = useState(0);
    const slideRef = useRef<any>(null);
    const [count, setCount] = useState(0);

    const showSlide = useCallback(() => {
        setDelta(0);
        if ($('body').hasClass('is-sliding')) return;

        // Loop through our slides
        if (slideRef.current) {
            _.each($(slideRef.current).find('.slide'), (slide, i): any => {
                $(slide).toggleClass('is-active', i === currentSlideIndex);
                $(slide).toggleClass('is-prev', i === currentSlideIndex - 1);
                $(slide).toggleClass('is-next', i === currentSlideIndex + 1);

                // Add and remove is-sliding class
                $('body').addClass('is-sliding');
                setTimeout(() => {
                    $('body').removeClass('is-sliding');
                }, 500);
            });
        }
    }, [currentSlideIndex]);

    useEffect(() => {
        if (slideRef.current) setNumSlides(_.size($(slideRef.current).children()));
    }, []);

    useEffect(() => {
        showSlide();
    }, [showSlide]);

    const handlePrevClick = () => {
        setCount(0);
        if (currentSlideIndex <= 0) setCurrentSlideIndex(numSlides - 1);
        else setCurrentSlideIndex(currentSlideIndex - 1);
    };

    const handleNextClick = useCallback(() => {
        setCount(0);
        if (currentSlideIndex >= numSlides - 1) setCurrentSlideIndex(0);
        else setCurrentSlideIndex(currentSlideIndex + 1);
    }, [currentSlideIndex, numSlides]);

    // 5s click next
    useEffect(() => {
        const time = setInterval(() => {
            if (count === 5) {
                handleNextClick();
                setCount(0);
            } else setCount(prev => prev + 1);
        }, 1000);
        return () => clearInterval(time);
    }, [count, handleNextClick]);

    // Scroll
    const handleSlideScroll = (e?: any) => {
        if (e.type === 'wheel') {
            if (e.detail < 0 || e.wheelDelta > 0) {
                setDelta(delta - 1);

                if (Math.abs(delta) >= scrollThreshold) {
                    handlePrevClick();
                }
            } else {
                setDelta(delta + 1);

                if (delta >= scrollThreshold) {
                    handleNextClick();
                }
            }
        }
        return false;
    };

    // addEventListener
    useEffect(() => {
        // document.addEventListener('wheel', handleSlideScroll);
        // document.addEventListener('mousewheel', handleSlideScroll);
        // document.addEventListener('whDOMMouseScrolleel', handleSlideScroll);
        // return () => {
        //     window.removeEventListener('wheel', handleSlideScroll);
        //     window.removeEventListener('mousewheel', handleSlideScroll);
        //     window.removeEventListener('DOMMouseScroll', handleSlideScroll);
        // };
    });

    return (
        <div className={styles.root}>
            <Navigation pages={pages} />
            <main className="container">
                <section className="slides-nav">
                    <nav className="slides-nav__nav">
                        <button type="button" className="slides-nav__prev js-prev" onClick={handlePrevClick}>
                            Prev
                        </button>
                        <button type="button" className="slides-nav__next js-next" onClick={handleNextClick}>
                            Next
                        </button>
                    </nav>
                </section>
                <section className="slides" ref={slideRef}>
                    <section className="slide">
                        <div className="slide__content">
                            <figure className="slide__figure">
                                <div
                                    className="slide__img one"
                                    // style="background-image: url(https://source.unsplash.com/nfTA8pdaq9A/2000x1100)"
                                />
                            </figure>
                            <header className="slide__header">
                                <h2 className="slide__title">
                                    <span className="title-line">
                                        <span>Online English</span>
                                    </span>
                                    <span className="title-line">
                                        <span>Best Courses</span>
                                    </span>
                                </h2>
                            </header>
                        </div>
                    </section>

                    <section className="slide">
                        <div className="slide__content">
                            <figure className="slide__figure">
                                <div
                                    className="slide__img two"
                                    // style="background-image: url(https://source.unsplash.com/okmtVMuBzkQ/2000x1100)"
                                />
                            </figure>
                            <header className="slide__header">
                                <h2 className="slide__title">
                                    <span className="title-line">
                                        <span>Book</span>
                                    </span>
                                    <span className="title-line">
                                        <span>Coffee</span>
                                    </span>
                                </h2>
                            </header>
                        </div>
                    </section>

                    {/* <section className="slide">
                        <div className="slide__content">
                            <figure className="slide__figure">
                                <div
                                    className="slide__img three"
                                    // style="background-image: url(https://source.unsplash.com/WuQME0I_oZA/2000x1100)"
                                />
                            </figure>
                            <header className="slide__header">
                                <h2 className="slide__title">
                                    <span className="title-line">
                                        <span>This Right</span>
                                    </span>
                                    <span className="title-line">
                                        <span>Here Makes Three</span>
                                    </span>
                                </h2>
                            </header>
                        </div>
                    </section>

                    <section className="slide">
                        <div className="slide__content">
                            <figure className="slide__figure">
                                <div
                                    className="slide__img four"
                                    // style="background-image: url(https://source.unsplash.com/NsWcRlBT_74/2000x1100)"
                                />
                            </figure>
                            <header className="slide__header">
                                <h2 className="slide__title">
                                    <span className="title-line">
                                        <span>How Now</span>
                                    </span>
                                    <span className="title-line">
                                        <span>Part Four More</span>
                                    </span>
                                </h2>
                            </header>
                        </div>
                    </section> */}
                </section>
            </main>
        </div>
    );
}

export default Home;
