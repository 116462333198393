import { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { MenuTypeProps } from '@routers/menu-pages';
import { changePageActived } from '../../stores/ReduxStore';
import styles from './NavItem.module.scss';

export type NavItemProps = {
    item: MenuTypeProps & { target?: '_self' | '_blank' };
    level?: number;
};

function NavItem(props: NavItemProps) {
    const { item, level = 1 } = props;
    const dispatch = useDispatch();
    const currentPageActived = useSelector(
        (state: { mainStore: InitDataType }) => state.mainStore.currentPageActived
    );

    let itemTarget = '_self';
    if (item.target) itemTarget = '_blank';

    const handleChangePage = () => {
        item.url && dispatch(changePageActived({ url: item.url }));
    };

    return (
        <li
            className={styles.root}
            data-actived={
                currentPageActived === item.url || (currentPageActived === '/' && item.url === '/home')
            }
            data-level={level}
        >
            <Link
                {...props}
                to={item.url || ''}
                target={itemTarget}
                className={styles.link}
                onClick={handleChangePage}
            >
                {item?.icon && (
                    <>
                        {item?.icon === 'list-style' ? (
                            <span className={styles.iconListStyle} />
                        ) : (
                            <span className={styles.icon}>{item?.icon}</span>
                        )}
                    </>
                )}
                <span className={styles.title}>{item.title}</span>
            </Link>
        </li>
    );
}

export default memo(NavItem);
