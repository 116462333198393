import Text from 'qnb-ui/src/components/Text';
import Heading from 'qnb-ui/src/components/Heading';
import type { PostDetailProps } from '../types';
import styles from './Detail.module.scss';

function Detail(props: PostDetailProps) {
    return (
        <article className={styles.root}>
            <div className="_nb-post-type-1_thumbnail">
                <a className="_link-image" href="/#">
                    <img
                        src="https://i.pinimg.com/564x/ef/d4/01/efd4012581aa59fa0cadc2d29e7faa58.jpg    "
                        alt=""
                    />
                </a>
            </div>
            <div className="mixed-detail">
                <div className="_nb-post-type-1_header">
                    <span className="_category">
                        <a href="/#" className="_category-name">
                            Adventure
                        </a>
                    </span>
                    <Heading className="entry-title">ISLE OF WINDS NOVEL REVIEW</Heading>
                    {/* <h2 className="entry-title">
                        <a href="/#">ISLE OF WINDS NOVEL REVIEW</a>
                    </h2> */}
                    <div className="_post-meta">
                        <span className="_post-meta-author">
                            by <a href="/#"> Tony Gray </a>
                        </span>
                        <span className="_post-meta-date">June 30, 2017</span>
                    </div>
                </div>
                <div className="_nb-post-type-1_content">
                    <Text>
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec,
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec
                    </Text>
                    <Text>
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec,
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec
                    </Text>
                    <Text>
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec,
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec
                    </Text>
                    <Text>
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec,
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec
                    </Text>
                    <Text>
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec,
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec
                    </Text>
                    <Text>
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec,
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec
                    </Text>
                    <Text>
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec,
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec
                        Lommodo ligula eget dolor. Aenean massa. Cum sociis que penatibus et magnis dis
                        parturient montes lorem, nascetur ridiculus mus. Donec quam felis, ultricies nec
                    </Text>
                </div>
            </div>
        </article>
    );
}

export default Detail;
