// Frontend Page
export const HOME_URL = '/home';
export const POSTS_URL = '/posts';
export const CHATAI_URL = '/chatai';
export const LOVE_STORY = '/love-story';
export const ENGLISH = '/english';

export const COPYRIGHT_ICON = `©`;
export const COPYRIGHT_YEAR = `2024`;
export const COPYRIGHT_AUTHOR = `Mina’s English`;

export const routerPath = {
    ENGLISH: '/english',
    EXAM: '/exam-scoring'
};
