import _ from 'lodash';
import { __ } from 'qnb-ui/src/helpers/i18n';
import { useState } from 'react';

import NavCollapse from './NavCollapse';
import NavGroup from './NavGroup';
import NavItem from './NavItem';
import styles from './Sidebar.module.scss';

export type MenuTypeProps = {
    id: string;
    title?: string;
    type?: 'group' | 'collapse' | 'item';
    url?: string;
    icon?: Element | React.ReactElement | JSX.Element | 'list-style';
    breadcrumbs?: boolean;
    children?: MenuTypeProps[];
};

export type SidebarProps = {
    menu?: MenuTypeProps[];
    sidebarOpen?: boolean;
    currentPageActived?: string;
    level?: number;
    pageChange?: (url?: string) => void;
    toggleSidebar?: (status?: boolean) => void;
};

function Sidebar(props: SidebarProps) {
    const { menu, toggleSidebar, ...rest } = props;

    const [currentCollapse, setCurrentCollapse] = useState<string>('');

    const handleChangeCollapse = (key: string) => {
        let currentKey = '';
        if (currentCollapse !== key) currentKey = key;

        setCurrentCollapse(currentKey);
    };

    return (
        <div className={`${styles.root}${!rest?.sidebarOpen ? ' side-collapsed' : ''}`}>
            {_.map(menu, item => {
                switch (item.type) {
                    case 'group':
                        return (
                            <NavGroup
                                key={item.id}
                                group={item}
                                currentCollapse={currentCollapse}
                                changeCollapsed={handleChangeCollapse}
                                {...rest}
                            />
                        );
                    case 'collapse':
                        return (
                            <NavCollapse
                                key={item.id}
                                collapse={item}
                                level={1}
                                currentCollapse={currentCollapse}
                                changeCollapsed={handleChangeCollapse}
                                {...rest}
                            />
                        );
                    case 'item':
                        return <NavItem key={item.id} item={item} level={1} {...rest} />;

                    default:
                        return <p>{__('Error')}</p>;
                }
            })}
        </div>
    );
}

export default Sidebar;
