import styles from './LoveStory.module.scss';
import ThreeYear from './story-list/ThreeYear';

function LoveStory() {
    return (
        <div className={styles.root}>
            <ThreeYear />
        </div>
    );
}

export default LoveStory;
