import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import './App.scss';
import Routers from './routers';
import { changePageActived } from './stores/ReduxStore';

function App() {
    const dispatch = useDispatch();

    useEffect(() => {
        const { pathname } = window.location;
        pathname && dispatch(changePageActived({ url: pathname }));
    }, [dispatch]);

    return (
        <BrowserRouter basename="/">
            <Routers />
        </BrowserRouter>
    );
}

export default App;
