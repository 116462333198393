import _ from 'lodash';

export const windowSpeak = (lang: 'en-US' | 'en-GB', text: string) => {
    let utterance = new SpeechSynthesisUtterance();
    const voices = speechSynthesis.getVoices();

    utterance.text = text;
    utterance.voice = _.find(voices, voice => voice.lang === lang) || voices[1];

    window.speechSynthesis.speak(utterance);
};
