// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mEjXNt9vd6a13zEFnSMu{width:100%;max-width:950px;margin:auto;font-size:14px;font-family:"Alice",serif;color:#313131;text-align:left}.mEjXNt9vd6a13zEFnSMu a{cursor:pointer;text-decoration:none;outline:none}`, "",{"version":3,"sources":["webpack://./src/views/frontend/components/post/Detail/Detail.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,eAAA,CACA,WAAA,CACA,cAAA,CACA,yBAAA,CACA,aAAA,CACA,eAAA,CAGJ,wBACI,cAAA,CACA,oBAAA,CACA,YAAA","sourcesContent":[".root {\n    width: 100%;\n    max-width: 950px;\n    margin: auto;\n    font-size: 14px;\n    font-family: \"Alice\", serif;\n    color: #313131;\n    text-align: left\n}\n\n.root :global( a) {\n    cursor: pointer;\n    text-decoration: none;\n    outline: none\n} \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `mEjXNt9vd6a13zEFnSMu`
};
export default ___CSS_LOADER_EXPORT___;
