import { CHATAI_URL, ENGLISH, HOME_URL, POSTS_URL } from '../constants/app';

export type MenuTypeProps = {
    id: string;
    title?: string;
    type?: 'collapse' | 'item' | 'group';
    url?: string;
    icon?: Element | React.ReactElement | JSX.Element | 'list-style';
    breadcrumbs?: boolean;
    children?: MenuTypeProps[];
};

export const pages: MenuTypeProps[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'item',
        url: HOME_URL,
        breadcrumbs: false
    },
    {
        id: 'posts',
        title: 'Posts',
        type: 'item',
        url: POSTS_URL,
        breadcrumbs: false
    }
    // {
    //     id: 'chatai',
    //     title: 'Chat.AI',
    //     type: 'item',
    //     url: CHATAI_URL,
    //     breadcrumbs: false
    // },
    // {
    //     id: ENGLISH,
    //     title: 'English',
    //     type: 'item',
    //     url: ENGLISH,
    //     breadcrumbs: false
    // }
];
