// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RdXwe771kQ6qsrQ5tkFn{width:100%;min-height:100vh;background:linear-gradient(-45deg, #FFC796 0%, #FF6B95 100%);color:#fff;font-size:1.1em;display:flex;justify-content:center;align-items:center}`, "",{"version":3,"sources":["webpack://./src/views/frontend/PleaseTellMeWhy/LoveStory.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,gBAAA,CACA,4DAAA,CACA,UAAA,CACA,eAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA","sourcesContent":[".root {\n    width: 100%;\n    min-height: 100vh;\n    background: linear-gradient(-45deg, #FFC796 0%, #FF6B95 100%);;\n    color: #fff;\n    font-size: 1.1em;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `RdXwe771kQ6qsrQ5tkFn`
};
export default ___CSS_LOADER_EXPORT___;
