import { type WordType } from '../../../types';

const customizable: WordType = {
    word: `customizable`,
    pronUK: `/ˈkʌstəmaɪzəbl/`,
    pronUS: `/ˈkʌstəmaɪzəbl/`,
    types: [
        {
            typeName: `adjective`,
            mean: `Tuỳ chỉnh`,
            ex: [
                {
                    sentence: `We offer customizable ringtones for your phone.`,
                    mean: `Chúng tôi cung cấp nhạc chuông có thể tuỳ chỉnh cho điện thoại của bạn.`
                }
            ]
        }
    ]
};

export default customizable;
