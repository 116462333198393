import { type WordType } from '../../../types';

const purchase: WordType = {
    word: `purchase`,
    pronUK: `/ˈpɜː.tʃəs/`,
    pronUS: `/ˈpɝː.tʃəs/`,
    types: [
        {
            typeName: `verb`,
            mean: `Mua, tậu`,
            ex: [
                {
                    sentence: `Tickets must be purchased two weeks in advance.`,
                    mean: `Vé phải được mua trước hai tuần.`
                },
                {
                    sentence: `Except under clearly defined circumstances, it is illegal in Britain for a company to purchase its own shares.`,
                    mean: `Ngoại trừ những trường hợp được xác định rõ ràng, việc một công ty mua cổ phiếu của chính mình là bất hợp pháp ở Anh`
                }
            ]
        },
        {
            typeName: 'noun',
            mean: `Điểm tựa, chỗ dựa vào, sắm được, mua được`,
            ex: [
                {
                    sentence: `How do you wish to pay for your purchases?`,
                    mean: `Bạn muốn thanh toán các giao dịch mua hàng của mình bằng cách nào.`
                },
                {
                    sentence: `New restrictions have been placed on the purchase of guns.`,
                    mean: `Những hạn chế mới đã được đặt ra đối với việc mua súng.`
                }
            ]
        }
    ]
};

export default purchase;
