import React from 'react';

import { ENGLISH, LOVE_STORY, routerPath } from '../constants/app';

/** Backend */
import Backend from '../views/backend/Backend';
import CreateNewCategory from '../views/backend/CreateNewCategory';
import CreateNewPost from '../views/backend/CreateNewPost';
import CreateNewTag from '../views/backend/CreateNewTag';
import Dashboard from '../views/backend/Dashboard';
import ListCategory from '../views/backend/ListCategory';
import ListPost from '../views/backend/ListPost';
import ListTag from '../views/backend/ListTag';
import Docs from '../views/backend/components/Docs';
import Login from '../views/backend/components/Login';
import Settings from '../views/backend/components/Settings';

/** Frontend */
import ChatAI from '../views/frontend/ChatAI';
import English from '../views/frontend/English';
import ExamScoring from '../views/frontend/ExamScoring';
import Home from '../views/frontend/Home';
import LoveStory from '../views/frontend/LoveStory';
import PleaseTellMeWhy from '../views/frontend/PleaseTellMeWhy';
import Posts from '../views/frontend/Posts';
import Detail from '../views/frontend/components/post/Detail';

export type RouterDataType = {
    path: string;
    element?: Element | React.ReactElement;
    children?: RouterDataType[];
};

const mainRouter: RouterDataType = {
    path: '/',
    children: [
        {
            path: '*',
            element: <div>Error</div>
        },
        {
            path: '/',
            children: [
                {
                    path: '/',
                    element: <Home />
                },
                {
                    path: 'home',
                    element: <Home />
                },
                {
                    path: 'posts',
                    element: <Posts />
                },
                {
                    path: 'post-detail',
                    children: [
                        {
                            path: '*',
                            element: <Detail />
                        }
                    ]
                },
                {
                    path: 'chatAI',
                    element: <ChatAI />
                },
                {
                    path: LOVE_STORY,
                    element: <LoveStory />
                },
                {
                    path: 'ptmw',
                    element: <PleaseTellMeWhy />
                },
                {
                    path: ENGLISH,
                    element: <English />
                },
                {
                    path: routerPath.EXAM,
                    element: <ExamScoring />
                }
                // {
                //     path: 'post-detail',
                //     element: <PostDetail />
                // }
            ]
        },
        {
            path: 'admin-login',
            element: <Login />
        },
        {
            path: 'admin',
            element: <Backend />,
            children: [
                {
                    path: 'dashboard',
                    element: <Dashboard />
                },
                {
                    path: 'post',
                    children: [
                        {
                            path: 'create-new',
                            element: <CreateNewPost />
                        },
                        {
                            path: 'list',
                            element: <ListPost />
                        }
                    ]
                },
                {
                    path: 'category',
                    children: [
                        {
                            path: 'create-new',
                            element: <CreateNewCategory />
                        },
                        {
                            path: 'list',
                            element: <ListCategory />
                        }
                    ]
                },
                {
                    path: 'tag',
                    children: [
                        {
                            path: 'create-new',
                            element: <CreateNewTag />
                        },
                        {
                            path: 'list',
                            element: <ListTag />
                        }
                    ]
                },
                {
                    path: 'settings',
                    element: <Settings />
                },
                {
                    path: 'docs',
                    element: <Docs />
                }
            ]
        }
    ]
};

export default mainRouter;
