import { type WordType } from '../../../types';

const seasonal: WordType = {
    word: `seasonal`,
    pronUK: `/ˈsiː.zən.əl/`,
    pronUS: `/ˈsiː.zən.əl/`,
    types: [
        {
            typeName: `adjective`,
            mean: `Theo mùa`,
            ex: [
                {
                    sentence: `Seasonal vegetables.`,
                    mean: `Các loại rau theo mùa.`
                },
                {
                    sentence: `Animals have to find ways of coping with seasonal changes.`,
                    mean: `Động vật phải tìm cách đối phó với những thay đổi theo mùa.`
                }
            ]
        }
    ]
};

export default seasonal;
